import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Label,
  Input,
  Form,
  FormFeedback,
  Button,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { invokeApiCall } from "../../api"
import { isEmpty } from "lodash"
import { useFormik } from "formik"
import { formatWorkflowNode } from "../../templates/tableCol"

const SearchReport = props => {
  //meta title
  document.title = "Search Report"
  const [record, setRecord] = useState({})

  const formValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      reference_number: "",
    },
    onSubmit: () => {
      searchReport()
    },
  })

  useEffect(() => {}, [])

  const searchReport = () => {
    const formValues = formValidation.values
    retrieveReport(formValues)
  }

  const retrieveReport = payload => {
    invokeApiCall("SEARCH_INCOMING_REPORT", payload).then(res => {
      const results = res.data
      setRecord(results)
    })
  }

  const handleDeleteEvent = () => {
    // setshowModal(false);
    toggle()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Incoming report" breadcrumbItem="Search Report" />

          {/* {!isEmpty(record) && ( */}
          <>
            <Card>
              <Form
                className="form-horizontal my-5"
                onSubmit={e => {
                  e.preventDefault()
                  formValidation.handleSubmit()
                  return false
                }}
              >
                <div className="text-center">
                  <Row className="justify-content-center mx-2">
                    <Col lg="5">
                      <h4 className="fw-semibold">Enter Reference Number</h4>
                      <div className="my-3">
                        <Input
                          type={"text"}
                          className="form-control"
                          id="basicpill-email-input4"
                          placeholder="Reference number"
                          name="reference_number"
                          onChange={formValidation.handleChange}
                          onBlur={formValidation.handleBlur}
                          value={formValidation.values.reference_number || ""}
                          invalid={
                            formValidation.touched.reference_number &&
                            formValidation.errors.reference_number
                              ? true
                              : false
                          }
                        ></Input>
                        {formValidation.touched.reference_number &&
                        formValidation.errors.reference_number ? (
                          <FormFeedback type="invalid">
                            {formValidation.errors.reference_number}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mt-4">
                        <Button type="submit" color="primary">
                          Search
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
              {!isEmpty(record) && (
                <Card>
                  <CardBody>
                    <div className="mt-5">
                      <h5 className="mb-3">Report Results :</h5>

                      <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Reference Number
                              </th>
                              <td>{record?.reference_number}</td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Status
                              </th>
                              <td>{record?.status}</td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Platform
                              </th>
                              <td>{record?.platform}</td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Report
                              </th>
                              <td>{record?.input_text}</td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Current Stage
                              </th>

                              <td>
                                {" "}
                                {formatWorkflowNode(
                                  record?.workflow_node?.current_node
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th
                                scope="row"
                                style={{ width: "400px" }}
                                className={"text-capitalize"}
                              >
                                Action
                              </th>

                              <td>
                                <Link
                                  to={{
                                    pathname: `/report/${record.id}`,
                                  }}
                                  className="btn btn-primary btn-sm w-xs"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Card>
          </>
        </Container>
      </div>
    </React.Fragment>
  )
}

SearchReport.propTypes = {
  match: PropTypes.object,
}

export default withRouter(SearchReport)
