import React, { useEffect, useState } from "react"
import withRouter from "components/Common/withRouter"
import { invokeApiCall } from "../../api"
import {
    Col,
    Row,
    Form,
    Button,
    Label,
    Input,
    FormFeedback,
} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { useFormik } from "formik"

const UserFilter = ({ fetchRecords }) => {
    const formValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            name: "",
            phone_number: "",
        },
        onSubmit: values => {
            const payload = {
                "email__icontains": values.email,
                "first_name__icontains": values.name,
                "phone_number__icontains": values.phone_number,
            }
            fetchRecords(payload)
        }
    })

    return (
        <React.Fragment>
            <Row>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        formValidation.handleSubmit()
                        return false
                    }}
                >
                    <Row className="mb-3 d-flex align-items-center">
                        <Col lg="3">
                            <Label for="basicpill-email-input4">Name</Label>
                            <Input
                                type="tel"
                                placeholder="Enter name"
                                name="name"
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.name || ""}
                                invalid={
                                    formValidation.touched.name &&
                                        formValidation.errors.name
                                        ? true
                                        : false
                                }
                            />
                            {(formValidation.touched.name && formValidation.errors.name) && (
                                <FormFeedback type="invalid">
                                    {formValidation.errors.name}
                                </FormFeedback>
                            )}
                        </Col>
                        <Col lg="3">
                            <Label for="basicpill-email-input4">Phone Number</Label>
                            <Input
                                type="tel"
                                placeholder="Enter phone number"
                                name="phone_number"
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.phone_number || ""}
                                invalid={
                                    formValidation.touched.phone_number &&
                                        formValidation.errors.phone_number
                                        ? true
                                        : false
                                }
                            />
                            {(formValidation.touched.phone_number && formValidation.errors.phone_number) && (
                                <FormFeedback type="invalid">
                                    {formValidation.errors.phone_number}
                                </FormFeedback>
                            )}
                        </Col>
                        <Col lg="3">
                            <Label for="basicpill-email-input4">Email</Label>
                            <Input
                                type="tel"
                                placeholder="Enter email"
                                name="email"
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.email || ""}
                                invalid={
                                    formValidation.touched.email &&
                                        formValidation.errors.email
                                        ? true
                                        : false
                                }
                            />
                            {(formValidation.touched.email && formValidation.errors.email) && (
                                <FormFeedback type="invalid">
                                    {formValidation.errors.email}
                                </FormFeedback>
                            )}
                        </Col>
                        <Col lg='3' className="mt-4">
                            <Button
                                type="submit"
                                color="primary"
                                className="w-md"
                            >
                                Filter
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </React.Fragment>
    )
}

export default withRouter(UserFilter)
