import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import { Container, Row, Col, Card, UncontrolledTooltip } from "reactstrap"
import { invokeApiCall } from "../../api"
import { getAuthenticatedUser } from "../../api/user"
import CardUser from "./card-user"
import MiniWidget from "./mini-widget"

import {
  OrderId,
  DateFormatter,
  Total,
  PaymentStatus,
  formatDate,
} from "../../templates/tableCol"
import { Link } from "react-router-dom"
import { checkPermissions } from "permissions/ability"
import { useDispatch } from "react-redux"
import { setNotificationCount } from "store/actions"

const Dashboard = props => {
  //meta title
  document.title = "Dashboard |  TIK"
  const [analyticRecords, setanalyticRecords] = useState({})
  const [analyticsDetails, setanalyticsDetails] = useState({})
  const [userDetails, setuserDetails] = useState({})
  const dispatch = useDispatch()

  const fetchanalyticRecords = () => {
    invokeApiCall("FETCH_DASHBOARD_ANALYTICS", {}).then(res => {
      const results = res?.data
      populateAnalyticsData(results)
    })
  }
  useEffect(() => {
    fetchanalyticRecords()
  }, [])
  useEffect(() => {
    const user = getAuthenticatedUser()
    setuserDetails(user)
  }, [])

  const populateReports = reportInstance => {
    const reports = [
      {
        title: "New Reports",
        iconClass: "bx-copy-alt",
        description: reportInstance?.new_reports | 0,
        action_link: "/regions",
        color: "success",
        action_name: "View more",
        permission: true,
      },
      {
        title: "Referred Reports",
        iconClass: "bx-archive-in",
        description: reportInstance?.reffered_reports | 0,
        action_link: "/new-reports",
        color: "warning",
        action_name: "View more",
        permission: true,
      },
      {
        title: "Completed Reports",
        iconClass: "bx-purchase-tag-alt",
        description: reportInstance?.completed_reports | 0,
        action_link: "/organizations",
        color: "success",
        action_name: "View more",
        permission: true,
        // permission: checkPermissions(["can_view_organization_analytics"]),
      },
      {
        title: "Banned Reports",
        iconClass: "bx-purchase-tag-alt",
        description: reportInstance?.banned_reports | 0,
        action_link: "/users",
        color: "danger",
        action_name: "View more",
        permission: true,
      },
    ]
    return reports
  }

  const analyticsOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "Weekly reports trail",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      // {
      //   name: "Male",
      //   data: [
      //     49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
      //     95.6, 54.4,
      //   ],
      // },
      // {
      //   name: "Female",
      //   data: [
      //     83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5, 106.6,
      //     92.3,
      //   ],
      // },
    ],
  }

  const populateAnalyticsData = results => {

    const report_trail = results?.chart_trail?.reports || []

    const chartFormat = {
      type: 'line',
      name: "Reports",
      data: report_trail?.map(item => item.value),
    }
    const categories = report_trail?.map(item => formatDate(item.label, "DD MMM"))
    analyticsOptions['xAxis']['categories'] = categories
    analyticsOptions['series'].push(chartFormat)

    let resultsData = {}
    resultsData = { ...results }
    resultsData["analyticsOptions"] = analyticsOptions
    resultsData["reports"] = populateReports(results)
    setanalyticsDetails(resultsData)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          {/* <Row>
            <Col xl="12">
              <CardUser user={userDetails} />
            </Col>

          </Row> */}
          <Col xl="12">
            <Row>
              <MiniWidget analyticsDetails={analyticsDetails} />
            </Row>
          </Col>
          <Card>
            {/* <CardBody>
          <div className="mb-4 h4 card-title">Latest Transactions</div>
          <TableContainer
            columns={columns}
            data={latestTransaction}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={6}
          />
        </CardBody> */}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  match: PropTypes.object,
}

export default withTranslation()(Dashboard)
