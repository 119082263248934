import React, { useEffect, useState } from "react"
import withRouter from "components/Common/withRouter"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { invokeApiCall } from "../../api"
import {
  Col,
  Container,
  Card,
  CardBody,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import classnames from "classnames"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import AnalyticsFilter from "./AnalyticsFilter"
import AnalyticsList from "./AnalyticsList"

const QuestionDashboard = () => {
  const pageName = "Reports by questions"
  document.title = pageName
  const [activeTab, setactiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }
  const [records, setRecords] = useState([])
  const [analyticsOptions, setanalyticsOptions] = useState({})

  const showAnalytics = (categories, seriesData) => {
    const options = {
      chart: {
        type: "column",
      },
      title: {
        text: pageName,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: categories,
        title: {
          text: 'Questions'
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: "Total",
        },
      },
      legend: {
        enabled: false
      },
      tooltip: {
        shared: false
      },
      plotOptions: {
        column: {
          grouping: true,
          shadow: true,
          pointPadding: 0,  // Space between columns in a group
          groupPadding: 0,  // Space between groups of columns
          borderWidth: 0
        }
      },
      series: seriesData,
    }

    setanalyticsOptions(options)
  }

  const fetchRecords = params => {
    invokeApiCall("FETCH_REPORTS_BY_QUESTION", params).then(res => {
      const data = res.data

      const reducedData = data.reduce((acc, item) => {
        if (!acc[item.quiz]) {
          acc[item.quiz] = 0;
        }
        acc[item.quiz] += item.count;
        return acc;
      }, {});

      // Convert the reduced data to an array of objects
      const recordList = Object.entries(reducedData).map(([quiz, total]) => ({
        name: quiz,
        total
      }));
      setRecords(recordList)

      const categories = [...new Set(data.map(item => item.quiz))]
      const answers = [...new Set(data.map(item => item.answer))];
      // Extract series (grouped by answers)
      const series = answers.map(answer => {
        return {
          name: answer,
          data: categories.map(category => {
            const item = data.find(d => d.quiz === category && d.answer === answer);
            return item ? item.count : 0;  // Default to 0 if no data
          }),
          pointWidth: 30
        };
      });
      showAnalytics(categories, series)
    })
  };
  useEffect(() => {
    fetchRecords()
  }, [])

  const fetchFilteredRecords = filterParams => {
    fetchRecords(filterParams)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Reports" breadcrumbItem={pageName} />
          <Card>
            <CardBody>
              <AnalyticsFilter fetchFilteredRecords={fetchFilteredRecords} />
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1")
                    }}
                  >
                    Graphical Analytics
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2")
                    }}
                  >
                    Tabular Analytics
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
            <TabContent activeTab={activeTab} className="body">
              <TabPane tabId="1">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={analyticsOptions}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <AnalyticsList records={records} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(QuestionDashboard)
