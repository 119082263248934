import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,

} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { getAuthenticatedUser } from '../../api/user';
import toastr from "toastr";
// actions
// import { editProfile, resetProfileFlag } from "../../store/actions";
import { invokeApiCall } from '../../api';
const UserProfile = () => {

  //meta title
  document.title = "Profile | Details";
  const [userDetails, setuserDetails] = useState({});
  const membershipValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      first_name: userDetails?.firstName,
      surname: userDetails?.lastName,
      other_names: userDetails?.otherNames,
      phone_number: userDetails?.phoneNumber,
      email: userDetails?.email,
      regions: userDetails?.regions,
      organization: userDetails?.organization,
      password: "",
      password_confirmation: "",
      // address: "",
    },
    validationSchema: Yup.object({
      // first_name: Yup.string().required("Please enter your first name"),
      // surname: Yup.string().required("Please enter your surname"),
      // other_names: Yup.string().required("Please Enter Your Password"),
      // phone_number: Yup.string().required("Please enter your phone number"),
      // email: Yup.string().required("Please enter your email"),
      password: Yup.string().required("Please enter your password"),
      password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
      // address: Yup.string().required("Please enter your address"),
    }),
    onSubmit: (values) => {
      const payload = {
        password: values.password,
        password_confirmation: values.password_confirmation,
      }
      updateProfileInfo(payload)
    }
  },);

  useEffect(() => {
    const user = getAuthenticatedUser();
    if (user?.isDefaultPassword) {
      toastr.warning("Kindly change password");
    }
    setuserDetails(user);

  }, []);

  const updateProfileInfo = (payload) => {
    invokeApiCall("UPDATE_MY_PROFILE", payload).then((res) => {
      if (res) {
        toastr.success("Details updated successfully");
      }
    })
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="" breadcrumbItem="Profile" />



          <h4 className="card-title mb-4">Profile details</h4>

          <Card>
            <CardBody>
              <Form onSubmit={(e) => {
                e.preventDefault();
                membershipValidation.handleSubmit();
                return false;
              }}>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        First name
                      </Label>
                      <Input
                        disabled
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        name="first_name"
                        placeholder="Enter Your First Name"
                        onChange={membershipValidation.handleChange}
                        onBlur={membershipValidation.handleBlur}
                        value={membershipValidation.values.first_name || ""}
                        invalid={
                          membershipValidation.touched.first_name && membershipValidation.errors.first_name ? true : false
                        }
                      />
                      {membershipValidation.touched.first_name && membershipValidation.errors.first_name ? (
                        <FormFeedback type="invalid">{membershipValidation.errors.first_name}</FormFeedback>
                      ) : null}
                    </div>

                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-lastname-input2">
                        Last name
                      </Label>
                      <Input
                        disabled
                        type="text"
                        className="form-control"
                        id="basicpill-lastname-input2"
                        placeholder="Enter Your Last Name"
                        name="surname"
                        onChange={membershipValidation.handleChange}
                        onBlur={membershipValidation.handleBlur}
                        value={membershipValidation.values.surname || ""}
                        invalid={
                          membershipValidation.touched.surname && membershipValidation.errors.surname ? true : false
                        }
                      />
                      {membershipValidation.touched.surname && membershipValidation.errors.surname ? (
                        <FormFeedback type="invalid">{membershipValidation.errors.surname}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-phoneno-input3">
                        Phone
                      </Label>
                      <Input
                        disabled
                        type="text"
                        className="form-control"
                        id="basicpill-phoneno-input3"
                        name="phone_number"
                        onChange={membershipValidation.handleChange}
                        onBlur={membershipValidation.handleBlur}
                        value={membershipValidation.values.phone_number || ""}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-email-input4">
                        Email
                      </Label>
                      <Input
                        disabled
                        type="email"
                        className="form-control"
                        id="basicpill-email-input4"
                        name="email"
                        onChange={membershipValidation.handleChange}
                        onBlur={membershipValidation.handleBlur}
                        value={membershipValidation.values.email || ""}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {
                    membershipValidation.values.regions &&
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-phoneno-input3">
                          Region
                        </Label>
                        <Input
                          disabled
                          type="text"
                          className="form-control"
                          id="basicpill-phoneno-input3"
                          name="regions"
                          onChange={membershipValidation.handleChange}
                          onBlur={membershipValidation.handleBlur}
                          value={membershipValidation.values.regions || ""}
                        />
                      </div>
                    </Col>
                  }
                  {
                    membershipValidation.values.organization &&
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-email-input4">
                          Organization
                        </Label>
                        <Input
                          disabled
                          type="text"
                          className="form-control"
                          id="basicpill-phoneno-input3"
                          name="organization"
                          onChange={membershipValidation.handleChange}
                          onBlur={membershipValidation.handleBlur}
                          value={membershipValidation.values.organization || ""}
                        />
                      </div>
                    </Col>
                  }


                </Row>
                <Row>
                  <h4 className="card-title mb-4 mt-4">Change Password</h4>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-password-input3">
                        New Password
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-password-input3"
                        placeholder="Enter Your Password"
                        name="password"
                        onChange={membershipValidation.handleChange}
                        onBlur={membershipValidation.handleBlur}
                        value={membershipValidation.values.password || ""}
                        invalid={
                          membershipValidation.touched.password && membershipValidation.errors.password ? true : false
                        }
                      />
                      {membershipValidation.touched.password && membershipValidation.errors.password ? (
                        <FormFeedback type="invalid">{membershipValidation.errors.password}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-repeat-password-input4">
                        Repeat password
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-repeat-password-input4"
                        placeholder="Repeat password"
                        name="password_confirmation"
                        onChange={membershipValidation.handleChange}
                        onBlur={membershipValidation.handleBlur}
                        value={membershipValidation.values.password_confirmation || ""}
                        invalid={
                          membershipValidation.touched.password_confirmation && membershipValidation.errors.password_confirmation ? true : false
                        }
                      />
                      {membershipValidation.touched.password_confirmation && membershipValidation.errors.password_confirmation ? (
                        <FormFeedback type="invalid">{membershipValidation.errors.password_confirmation}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <div className="text-start mt-6">
                  <Button type="submit" color="danger">
                    Save
                  </Button>
                </div>
              </Form>

            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
