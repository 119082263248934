import React from "react"
import withRouter from "components/Common/withRouter"
import {
    Col,
    Row,
    Form,
    Button,
    Input,
    FormFeedback,
} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { useFormik } from "formik"
import * as Yup from "yup";

const WordFilter = ({ fetchRecords }) => {
    const formValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
        },
        onSubmit: values => {
            const payload = {
                "name__icontains": values.name
            }
            fetchRecords(payload)
        }
    })

    return (
        <React.Fragment>
            <Row>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        formValidation.handleSubmit()
                        return false
                    }}
                >
                    <Row className="mb-3 d-flex align-items-center">
                        <Col lg="4">
                            <Input
                                type="tel"
                                placeholder="Search by name"
                                name="name"
                                onChange={formValidation.handleChange}
                                onBlur={formValidation.handleBlur}
                                value={formValidation.values.name || ""}
                                invalid={
                                    formValidation.touched.name &&
                                        formValidation.errors.name
                                        ? true
                                        : false
                                }
                            />
                            {(formValidation.touched.name && formValidation.errors.name) && (
                                <FormFeedback type="invalid">
                                    {formValidation.errors.name}
                                </FormFeedback>
                            )}
                        </Col>
                        <Col sm={6}>
                            <Button
                                type="submit"
                                color="primary"
                                className="w-md"
                            >
                                Filter
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </React.Fragment>
    )
}

export default withRouter(WordFilter)
