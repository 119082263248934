import swal from "sweetalert"
import { apiMapper, makeApiCall } from "./api"
import { decode as base64_decode, encode as base64_encode } from "base-64"
export let invokeApiCall = (module, payload) => {
  const selectedMapper = apiMapper[module]
  return makeApiCall(selectedMapper.method, selectedMapper.url, payload)
}
const host = process.env.REACT_APP_APP_URL

export let EncodeBase64 = inputValue => {
  let encoded = base64_encode(inputValue)
  return encoded
}
export let DecodeBase64 = inputValue => {
  let decoded = base64_decode(inputValue)
  return decoded
}

export let generateQRValue = (module, inputValue) => {
  const qrModules = {
    EVENT_CHECKIN: `${host}/events-checkin/${inputValue}`,
  }
  const qrData = qrModules[module]
  return qrData
}

export let invokeUpdateApiCall = (module, payload, product) => {
  const selectedMapper = apiMapper[module]
  return makeApiCall(
    selectedMapper.method,
    `${selectedMapper.url}/${product}`,
    payload
  )
}

export const initiateSwalPrompt = () => {
  return swal({
    title: "Are you sure?",
    icon: "warning",
    buttons: ["No", "Yes"],
    dangerMode: true,
    closeOnClickOutside: true,
    closeOnEsc: true,
  })
}
export let DEFAULT_PAGINATION_SIZE = 10
