import React from "react"
import withRouter from "components/Common/withRouter"
import {
  Col,
  Row,
  Form,
  Button,
  Label,
  Input,
  FormFeedback,
} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { useFormik } from "formik"

const SubscriberFilter = ({ fetchRecords }) => {
  const formValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone_number: null,
      created_at: null,
    },
    onSubmit: values => {
      const payload = {
        "phone_number__icontains": values.phone_number,
        "created_at": values.created_at || null
      }
      fetchRecords(payload)
    }
  })

  return (
    <React.Fragment>
      <Row>
        <Form
          onSubmit={e => {
            e.preventDefault();
            formValidation.handleSubmit()
            return false
          }}
        >
          <Row className="mb-3 d-flex align-items-center">
            <Col lg="4">
              <Label for="basicpill-email-input4">Phone Number</Label>
              <Input
                type="tel"
                placeholder="Enter phone number"
                name="phone_number"
                onChange={formValidation.handleChange}
                onBlur={formValidation.handleBlur}
                value={formValidation.values.phone_number || ""}
                invalid={
                  formValidation.touched.phone_number &&
                    formValidation.errors.phone_number
                    ? true
                    : false
                }
              />
              {(formValidation.touched.phone_number && formValidation.errors.phone_number) && (
                <FormFeedback type="invalid">
                  {formValidation.errors.phone_number}
                </FormFeedback>
              )}
            </Col>
            <Col lg="3" className="col-xl">
              <Label className="form-label">Date Added</Label>
              <Input
                type={"date"}
                selected={formValidation.values.created_at}
                name="created_at"
                onChange={formValidation.handleChange}
                onBlur={formValidation.handleBlur}
                value={formValidation.values.created_at || null}
                className="form-control"
              />
            </Col>
            <Col sm={6} className="mt-4">
              <Button
                type="submit"
                color="primary"
                className="w-md"
              >
                Filter
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(SubscriberFilter)
