import React, { useEffect, useState } from "react";
import withRouter from "components/Common/withRouter";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { invokeApiCall } from '../../api';
import {
    Col,
    Container,
    Card, CardBody,
    Row,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink

} from "reactstrap";
import classnames from "classnames";
import AnalyticsFilter from "./AnalyticsFilter"
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import AnalyticsList from './AnalyticsList';
const StatusDashboard = () => {
  const pageName = "Report by status"
  document.title = pageName;
  const [activeTab, setactiveTab] = useState("1");


  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const [records, setRecords] = useState([]);
  const [analyticsOptions, setanalyticsOptions] = useState({});

  const showAnalytics = (seriesData) => {
    const options = {
      chart: {
        type: 'column'
      },
      title: {
        text: pageName
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: ["Status"],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: false,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: seriesData
         
    }
      
    setanalyticsOptions(options);
  
  }
  
  const fetchRecords = (params) => {
    invokeApiCall("FETCH_REPORTS_BY_STATUS", params).then((res) => {
      const results = res.data;
           
      const analyticsData = [];
      setRecords(results)
      for (let result of results) {
        const totals = parseInt(result['total']);
        analyticsData.push({
          name: result['name'],
          data: [totals],
        });

      }
      showAnalytics(analyticsData);
            

    });
  }
  useEffect(() => {
    fetchRecords()
  }, [])

  const fetchFilteredRecords = (filterParams) => {
    fetchRecords(filterParams)

  }



  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Reports" breadcrumbItem={pageName} />
          <Card>
            <CardBody>
              <AnalyticsFilter fetchFilteredRecords={fetchFilteredRecords} />
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Graphical Analytics
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Tabular Analytics
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
            <TabContent activeTab={activeTab} className="body">
              <TabPane tabId="1">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={analyticsOptions}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <AnalyticsList records={records} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>   
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(StatusDashboard);
