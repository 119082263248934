import React, { useEffect, useState, useMemo } from "react"
import withRouter from "components/Common/withRouter"
import * as moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  initiateSwalPrompt,
  invokeApiCall,
  invokeUpdateApiCall,
} from "../../api"
import {
  Col,
  Container,
  Card,
  CardBody,
  Row,
  Label,
  Form,
  FormFeedback,
  Input,
  UncontrolledTooltip,
  Button,
  CardTitle,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import { OrderId, DefaultFormat, BooleanFormat } from "../../templates/tableCol"
import TableContainer from "../../components/Common/TableContainer"
import CustomModal from "../../components/Common/CustomModal"

const QuestionTypes = [
  {
    id: "OPEN_ENDED",
    name: "OPEN ENDED",
  },
  {
    id: "CLOSED",
    name: "CLOSED",
  },
  {
    id: "OPENING_REMARK",
    name: "OPENING REMARK",
  },
  {
    id: "CLOSING_REMARK",
    name: "CLOSING REMARK",
  },
  {
    id: "MODEL",
    name: "MODEL",
  },
  {
    id: "ANONYMOUS",
    name: "ANONYMOUS",
  },
  {
    id: "LANGUAGE_SWITCH",
    name: "LANGUAGE SWITCH",
  },
]

const QuestionList = () => {
  //meta title
  document.title = "Questions"
  const [record, setRecord] = useState()
  const [records, setRecords] = useState([])
  // const [formRows, setformRows] = useState([]);
  const [formRows, setFormRows] = useState([{ id: 1 }])
  const [paginationParams, setpaginationParams] = useState({})

  const [showModal, setshowModal] = useState(false)
  const [bulkQuestionOptions, setbulkQuestionOptions] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const onAddFormRow = () => {
    // const modifiedRows = [...formRows];
    // modifiedRows.push({ id: modifiedRows.length + 1 });
    setFormRows(modifiedRows)
  }
  const optionsValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // question: (record && record.question) || "",
      // question_type: (record && record.question_type) || "",
      // order_number: (record && record.order_number) || "",
      // question_model: (record && record.question_model) || "",
    },
    validationSchema: Yup.object({
      option: Yup.string().required("Please Enter Option"),
      swahili_option: Yup.string().required("Please Enter Swahili Option"),
      option_code: Yup.string().required("Please Enter Option Code"),
    }),
    onSubmit: values => { },
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      question: (record && record.question) || "",
      swahili_question: (record && record.swahili_question) || "",
      question_type: (record && record.question_type) || "",
      order_number: (record && record.order_number) || "",
      question_model: (record && record.question_model) || "",
      is_bio_question: (record && record.is_bio_question) || false,
    },
    validationSchema: Yup.object({
      question: Yup.string().required("Please Enter Question"),
      swahili_question: Yup.string().required("Tafadhali weka swali"),
      question_type: Yup.string().required("Please Select Question Type"),
      order_number: Yup.string().required("Please Enter Order Number"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateRecord = {
          id: record.id,
          question: values.question,
          swahili_question: values.swahili_question,
          question_type: values.question_type,
          order_number: values.order_number,
          question_model: values.question_model,
          is_bio_question: values.is_bio_question,

          question_options: bulkQuestionOptions,
        }
        updateEdittedRecord(updateRecord)
      } else {
        const newRecord = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          swahili_question: values["swahili_question"],
          question: values["question"],
          question_type: values["question_type"],
          order_number: values["order_number"],
          question_model: values["question_model"],
          is_bio_question: values["is_bio_question"],
          question_options: bulkQuestionOptions,
        }

        insertRecord(newRecord)
      }
    },
  })

  const handleDeleteEvent = () => {
    toggle()
  }
  const fetchRecords = params => {
    invokeApiCall("FETCH_QUESTIONS", params).then(res => {
      const results = res.data
      setpaginationParams({
        next: results?.next,
        previous: results?.previous,
      })
      setRecords(results.results)
    })
  }
  const NavigateToPage = responseData => {
    fetchRecords(responseData)
  }

  const optionsColumns = useMemo(
    () => [
      {
        Header: "English Option",
        accessor: "option",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DefaultFormat {...cellProps} />
        },
      },
      {
        Header: "Swahili Option",
        accessor: "swahili_option",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DefaultFormat {...cellProps} />
        },
      },
      {
        Header: "Option Code",
        accessor: "option_code",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DefaultFormat {...cellProps} />
        },
      },

      {
        Header: "Action",
        accessor: "view",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <Button
              type="button"
              color="danger"
              className="btn-sm btn-rounded"
              onClick={e => {
                e.preventDefault()
                deleteOption(row.original)
              }}
            >
              Remove
            </Button>
          )
        },
      },
    ],
    []
  )

  const createRecord = () => {
    setIsEdit(false)
    setshowModal(true)
  }

  const editRecord = id => {
    const params = id
    // fetch details & popup
    invokeApiCall("FETCH_QUESTION_DETAIL", params).then(res => {
      const results = res.data
      handleUserClick(results)
    })
  }
  const updateEdittedRecord = payload => {
    invokeApiCall("UPDATE_QUESTION", payload).then(res => {
      const results = res.data
      setshowModal(false)
      validation.handleReset()
      setbulkQuestionOptions([])
      fetchRecords()
    })
  }

  const insertRecord = payload => {
    invokeApiCall("CREATE_QUESTION", payload).then(res => {
      const results = res.data
      setshowModal(false)
      validation.handleReset()
      setbulkQuestionOptions([])
      fetchRecords()
    })
  }

  const removeRecord = payload => {
    initiateSwalPrompt().then(value => {
      if (value) {
        invokeUpdateApiCall("DELETE_QUESTION", {}, payload).then(res => {
          setbulkQuestionOptions([])
          fetchRecords()
        })
      }
    })
  }

  const insertOption = () => {
    const memberInfo = optionsValidation.values
    const newoptionDetails = { ...memberInfo }
    newoptionDetails["id"] = Number(new Date())
    newoptionDetails["action_type"] = "create"
    setbulkQuestionOptions(oldArray => [...oldArray, newoptionDetails])
    optionsValidation.resetForm()
  }

  const deleteOption = instance => {
    setbulkQuestionOptions(prev => prev.filter(record => record !== instance))
  }

  const handleUserClick = arg => {
    const details = arg

    setRecord({
      id: details.id,
      question: details.question,
      swahili_question: details.swahili_question,
      question_type: details.question_type,
      order_number: details.order_number,
      is_bio_question: details.is_bio_question,
      question_model: details.question_model,
    })
    setbulkQuestionOptions(details?.question_options)
    setIsEdit(true)
    setshowModal(true)
  }
  useEffect(() => {
    fetchRecords()
  }, [])
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y HH:MM:SS")
    return date1
  }
  const columns = useMemo(
    () => [
      {
        Header: "Question",
        accessor: "question",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "question_type",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          const value = {...cellProps, value: cellProps.row.original.question_type.replace("_", " ")}
          return <OrderId {...value} />
        },
      },
      {
        Header: "Order number",
        accessor: "order_number",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Is bio question",
        accessor: "is_bio_question",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <BooleanFormat {...cellProps} />
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div className="d-flex gap-3">
              <Button
                type="button"
                color="success"
                className="btn-sm btn-rounded"
                onClick={e => {
                  e.preventDefault()
                  editRecord(row.original.id)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Button>
              <Button
                type="button"
                color="danger"
                className="btn-sm btn-rounded"
                onClick={e => {
                  e.preventDefault()
                  removeRecord(row.original.id)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Remove
                </UncontrolledTooltip>
              </Button>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <CustomModal
        show={showModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setshowModal(false)}
        size="xl"
        addSubmitButton
        onActionSubmit={() => {
          validation.handleSubmit()
        }}
        title="Question"
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
          className="text-start"
        >
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">English question</Label>
                <Input
                  name="question"
                  type="text-area"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.question || ""}
                  invalid={
                    validation.touched.question && validation.errors.question
                      ? true
                      : false
                  }
                />
                {validation.touched.question && validation.errors.question ? (
                  <FormFeedback type="invalid">
                    {validation.errors.question}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Swahili question</Label>
                <Input
                  name="swahili_question"
                  type="text-area"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.swahili_question || ""}
                  invalid={
                    validation.touched.swahili_question &&
                      validation.errors.swahili_question
                      ? true
                      : false
                  }
                />
                {validation.touched.swahili_question &&
                  validation.errors.swahili_question ? (
                  <FormFeedback type="invalid">
                    {validation.errors.swahili_question}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label className="form-label">Question type</Label>

                <Input
                  // disabled={isEdit}
                  type={"select"}
                  className="form-control"
                  id="basicpill-email-input4"
                  name="question_type"
                  onChange={e => {
                    // const targetValue = e.target.value
                    // if (targetValue === convener && !regions.length) {
                    //   fetchRegions()
                    // } else if (targetValue === organization && !regions.length) {
                    //   fetchOrganizations()
                    // }
                    validation.handleChange(e)
                  }}
                  onBlur={validation.handleBlur}
                  value={validation.values.question_type || ""}
                  invalid={
                    validation.touched.question_type &&
                      validation.errors.question_type
                      ? true
                      : false
                  }
                >
                  <option value={""} disabled hidden></option>
                  <option value={""} disabled hidden></option>
                  {QuestionTypes?.length &&
                    QuestionTypes?.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Input>
                {validation.touched.question_type &&
                  validation.errors.question_type ? (
                  <FormFeedback type="invalid">
                    {validation.errors.question_type}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label className="form-label">Order Number</Label>
                <Input
                  name="order_number"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.order_number || ""}
                  invalid={
                    validation.touched.order_number &&
                      validation.errors.order_number
                      ? true
                      : false
                  }
                />
                {validation.touched.order_number &&
                  validation.errors.order_number ? (
                  <FormFeedback type="invalid">
                    {validation.errors.order_number}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="6">
              <div className="form-check form-check-inline">
                <Label className="form-check-label" for="is_bio_question">Is a bio question</Label>
                <Input
                  className="form-check-input form-check-inline"
                  id="is_bio_question"
                  name="is_bio_question"
                  type="checkbox"
                  defaultChecked={validation.values.is_bio_question}
                  checked={validation.values.is_bio_question}
                  // onChange={validation.handleChange}
                  onClick={(e) => {
                    validation.setFieldValue(
                      "is_bio_question",
                      !!!validation.values.is_bio_question
                    )
                  }}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.is_bio_question &&
                      validation.errors.is_bio_question
                      ? true
                      : false
                  }
                />
                {validation.touched.is_bio_question &&
                  validation.errors.is_bio_question ? (
                  <FormFeedback type="invalid">
                    {validation.errors.is_bio_question}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            {validation.values.question_type == "MODEL" && (
              <Col xs={6}>
                <div className="mb-3">
                  <Label className="form-label">System Record Type</Label>
                  <Input
                    name="question_model"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.question_model || ""}
                    invalid={
                      validation.touched.question_model &&
                        validation.errors.question_model
                        ? true
                        : false
                    }
                  />
                  {validation.touched.question_model &&
                    validation.errors.question_model ? (
                    <FormFeedback type="invalid">
                      {validation.errors.question_model}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            )}

            {['CLOSED', 'LANGUAGE_SWITCH', 'ANONYMOUS', 'LANGUAGE SWITCH'].includes(validation.values.question_type) && (
              <Card>
                <CardBody>
                  <CardTitle className="py-4">Question options</CardTitle>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      optionsValidation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col xs={5}>
                        <div className="mb-3">
                          <Label className="form-label">English Option</Label>
                          <Input
                            name="option"
                            type="text"
                            onChange={optionsValidation.handleChange}
                            onBlur={optionsValidation.handleBlur}
                            value={optionsValidation.values.option || ""}
                            invalid={
                              optionsValidation.touched.option &&
                                optionsValidation.errors.option
                                ? true
                                : false
                            }
                          />
                          {optionsValidation.touched.option &&
                            optionsValidation.errors.option ? (
                            <FormFeedback type="invalid">
                              {validation.errors.option}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={5}>
                        <div className="mb-3">
                          <Label className="form-label">Swahili option</Label>
                          <Input
                            name="swahili_option"
                            type="text"
                            onChange={optionsValidation.handleChange}
                            onBlur={optionsValidation.handleBlur}
                            value={
                              optionsValidation.values.swahili_option || ""
                            }
                            invalid={
                              optionsValidation.touched.swahili_option &&
                                optionsValidation.errors.swahili_option
                                ? true
                                : false
                            }
                          />
                          {optionsValidation.touched.swahili_option &&
                            optionsValidation.errors.swahili_option ? (
                            <FormFeedback type="invalid">
                              {validation.errors.swahili_option}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={5}>
                        <div className="mb-3">
                          <Label className="form-label">Option Code</Label>
                          <Input
                            name="option_code"
                            type="text"
                            onChange={optionsValidation.handleChange}
                            onBlur={optionsValidation.handleBlur}
                            value={optionsValidation.values.option_code || ""}
                            invalid={
                              optionsValidation.touched.option_code &&
                                optionsValidation.errors.option_code
                                ? true
                                : false
                            }
                          />
                          {optionsValidation.touched.option_code &&
                            optionsValidation.errors.option_code ? (
                            <FormFeedback type="invalid">
                              {optionsValidation.errors.option_code}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={5}>
                        <div className="d-grid">
                          <Label className="form-label">&nbsp;</Label>
                          <input
                            type="button"
                            className="btn btn-primary"
                            value="Add"
                            onClick={insertOption}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>

                  <TableContainer
                    columns={optionsColumns}
                    data={bulkQuestionOptions}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={6}
                  />
                </CardBody>
              </Card>
            )}
          </Row>
        </Form>
      </CustomModal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="questions" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={createRecord}
                    >
                      <i className="mdi mdi-plus me-1" />
                      Add Question
                    </Button>
                  </div>
                  <div className="mb-4 h4 card-title">All records</div>
                  <TableContainer
                    columns={columns}
                    data={records}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    paginationParams={paginationParams}
                    customPageSize={10}
                    NavigateToPage={NavigateToPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(QuestionList)
