import PropTypes from "prop-types";
import React, { useState, useEffect } from "react"
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/uwajibikaji.avif";
import { invokeApiCall } from '../../api';

const ResetPasswordPage = props => {

    //meta title
    document.title = "Reset Password | PAMCA";
    const params = props.router.params;
    const [resetPasswordStatus, setresetPasswordStatus] = useState(false);
    const [userEmail, setuserEmail] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {

        if (params && params.id) {
            setuserEmail(params.id)

        }
    }, [userEmail]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            password: '',
            password_confirmation: '',
            otp_code: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please enter new password"),
            otp_code: Yup.string().required("Please enter otp code"),
            password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: (values) => {
            const payload = { email: userEmail, ...values }
            forgotPassword(payload)
        }


    });
    const forgotPassword = (payload) => {
        invokeApiCall("RESET_PASSWORD", payload).then((res) => {
            setresetPasswordStatus(true)
        });
    }

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="bx bx-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-softbg-soft-primary">
                                    <Row>
                                        <Col xs={7}>
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Reset password</h5>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt=""
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        {resetPasswordStatus ? (
                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                Password update successfull.Kindly proceed to log in
                                            </Alert>
                                        ) : null}

                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">OTP Code</Label>
                                                <Input
                                                    name="otp_code"
                                                    className="form-control"
                                                    placeholder="Enter OTP Code"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.otp_code || ""}
                                                    invalid={
                                                        validation.touched.otp_code && validation.errors.otp_code ? true : false
                                                    }
                                                />
                                                {validation.touched.otp_code && validation.errors.otp_code ? (
                                                    <FormFeedback type="invalid">{validation.errors.otp_code}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">New password</Label>
                                                <Input
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="Enter new password"
                                                    type="password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.password || ""}
                                                    invalid={
                                                        validation.touched.password && validation.errors.password ? true : false
                                                    }
                                                />
                                                {validation.touched.password && validation.errors.password ? (
                                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Repeat password</Label>
                                                <Input
                                                    name="password_confirmation"
                                                    className="form-control"
                                                    placeholder="Repeat new password"
                                                    type="password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.password_confirmation || ""}
                                                    invalid={
                                                        validation.touched.password_confirmation && validation.errors.password_confirmation ? true : false
                                                    }
                                                />
                                                {validation.touched.password_confirmation && validation.errors.password_confirmation ? (
                                                    <FormFeedback type="invalid">{validation.errors.password_confirmation}</FormFeedback>
                                                ) : null}
                                            </div>
                                            <Row className="mb-3">
                                                <Col className="text-end">
                                                    <button
                                                        className="btn btn-primary w-md "
                                                        type="submit"
                                                    >
                                                        Reset
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Already have an account?{" "}
                                    <Link to="/login" className="fw-medium text-primary">
                                        {" "}
                                        Login now{" "}
                                    </Link>{" "}
                                </p>
                                <p>
                                    © {new Date().getFullYear()} Pamca. Crafted with{" "}
                                    <i className="mdi mdi-heart text-danger" /> for Pamca
                                </p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

ResetPasswordPage.propTypes = {
    history: PropTypes.object,
};

export default withRouter(ResetPasswordPage);
