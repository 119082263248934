import React, { useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import "react-datepicker/dist/react-datepicker.css"
import {
  DateFormatter,
  ApplicationStatus,
} from "../../templates/tableCol"
import TableContainer from "../../components/Common/TableContainer"
import { Button } from "reactstrap"
import './ReportList.css'
import { initiateSwalPrompt, invokeApiCall } from "api"
import toastr from "toastr"

const ReportList = props => {
  const { reportType } = props

  const columns = useMemo(
    () => [
      {
        Header: "Reference Number",
        accessor: "reference_number",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <p className="text-body fw-bold">
              {cellProps.value}
            </p>
          )
        },
      },
      {
        Header: "Report",
        accessor: "input_text",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <p className="text-body fw-bold text-trunc" title={cellProps.value}>
              {cellProps.value}
            </p>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <ApplicationStatus {...cellProps} />
        },
      },
      {
        Header: "Date Created",
        accessor: "created_at",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DateFormatter {...cellProps} />
        },
      },
      {
        Header: "Actions",
        Cell: ({ row }) => {
          return <TableActions row={row} reportType={reportType} />
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <TableContainer
        columns={columns}
        data={props.records}
        isGlobalFilter={false}
        paginationParams={props.paginationParams}
        isAddOptions={false}
        customPageSize={10}
        NavigateToPage={props.NavigateToPage}
      />
    </React.Fragment>
  )
}

export default ReportList


const TableActions = ({ row, reportType }) => {

  const navigate = useNavigate();

  const reportId = row.original.id;
  const dismissReport = (id) => {
    const payload = {
      id: id,
      action: "REPORT_NOT_ACTIONABLE",
      comment: "Dismiss report"
    }
    initiateSwalPrompt().then(value => {
      if (value) {
        invokeApiCall("ACT_ON_REPORT", payload).then(res => {
          if (res) {
            toastr.success("Success")
            navigate(0);
          }
        })
      }
    })
  }
  
  return (
    <div>
      <Link
        to={`/report/${reportId}`}
        className="btn btn-primary btn-sm w-xs m-1"
      >
        View
      </Link>
      {
        (
          reportType === 'new' &&
          ['REPORT_FORWARDED_TO_CONVENER', 'REPORT_RETURNED_TO_CONVENER'].includes(row.original?.workflow_node?.current_node)
        ) &&
        <Button
          size="sm"
          type="button"
          color="danger"
          className="w-sm m-1"
          onClick={() => {
            dismissReport(reportId)
          }}
        >
          Dismiss
        </Button>
      }
    </div>
  )
}
