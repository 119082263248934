import React, { useEffect, useState } from "react";
import withRouter from "components/Common/withRouter";
import { invokeApiCall } from '../../api';
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback

} from "reactstrap";
import { useFormik } from "formik";


const AnalyticsFilter = ({ fetchFilteredRecords }) => {
  const [ReportTypes, setReportTypes] = useState([]);
  const [Regions, setRegions] = useState([]);
  const [Organizations, setOrganizations] = useState([]);

  const formValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {},
  });
  const fetchRecords = (params) => {
    fetchFilteredRecords(params)
  }
  const filterRecords = () => {
    const filterPayload = { ...formValidation.values }
    fetchRecords(filterPayload)

  }
  const fetchreportTypes = (params) => {
    invokeApiCall("FETCH_REPORT_TYPES", params).then((res) => {
      const results = res.data;
      setReportTypes(results.results)
    });
  }
  const fetchRegions = (params) => {
    invokeApiCall("FETCH_REGIONS", params).then((res) => {
      const results = res.data;
      setRegions(results.results)

    });
  }
  const fetchOrganizations = (params) => {
    invokeApiCall("FETCH_ORGANIZATIONS", params).then((res) => {
      const results = res.data;
      setOrganizations(results.results)

    });
  }
  useEffect(() => {
    const filterParams = {
      "page_size": 1000
    }
    fetchreportTypes(filterParams)
    fetchRegions(filterParams)
    fetchOrganizations(filterParams)
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Form onSubmit={(e) => {
          // e.preventDefault();
          formValidation.handleSubmit();
          return false;
        }}>
          <Row className="mb-4">
            <Col lg={4} className="col-xl">
              <FormGroup className="mb-3">
                <Label className="form-label">Start Date</Label>
                <Input
                  type={"date"}
                  selected={formValidation.values.start_date}
                  name="start_date"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.start_date || ""}
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col sm={4} className="col-xl">
              <FormGroup className="mb-3">
                <Label className="form-label">End Date</Label>
                <Input
                  type={"date"}
                  selected={formValidation.values.end_date}
                  name="end_date"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.end_date || ""}
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Label for="basicpill-email-input4">
                  Region
                </Label>
                <Input
                  type={"select"}
                  className="form-control"
                  id="basicpill-email-input4"
                  placeholder="Select region"
                  name="region_id"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.region_id || ""}
                  invalid={
                    formValidation.touched.region_id && formValidation.errors.region_id ? true : false
                  }
                >
                  <option value={""} disabled hidden></option>
                  {
                    Regions?.length && Regions?.map((item, i) => (
                      <option key={i} value={item.id}>{item.name}</option>
                    ))
                  }
                </Input>
                {formValidation.touched.region_id && formValidation.errors.region_id ? (
                  <FormFeedback type="invalid">{formValidation.errors.region_id}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Label for="basicpill-email-input4">
                  Organizations
                </Label>
                <Input
                  type={"select"}
                  className="form-control"
                  id="basicpill-email-input4"
                  placeholder="Select organization"
                  name="organization_id"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.organization_id || ""}
                  invalid={
                    formValidation.touched.organization_id && formValidation.errors.organization_id ? true : false
                  }
                >
                  <option value={""} disabled hidden></option>
                  {
                    Organizations?.length && Organizations?.map((item, i) => (
                      <option key={i} value={item.id}>{item.name}</option>
                    ))
                  }
                </Input>
                {formValidation.touched.organization_id && formValidation.errors.organization_id ? (
                  <FormFeedback type="invalid">{formValidation.errors.organization_id}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Label for="basicpill-email-input4">
                  Report Category
                </Label>
                <Input
                  type={"select"}
                  className="form-control"
                  id="basicpill-email-input4"
                  placeholder="Select report category"
                  name="report_id"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.report_id || ""}
                  invalid={
                    formValidation.touched.report_id && formValidation.errors.report_id ? true : false
                  }
                >
                  <option value={""} disabled hidden></option>
                  {
                    ReportTypes?.length && ReportTypes?.map((item, i) => (
                      <option key={i} value={item.id}>{item.name}</option>
                    ))
                  }
                </Input>
                {formValidation.touched.report_id && formValidation.errors.report_id ? (
                  <FormFeedback type="invalid">{formValidation.errors.report_id}</FormFeedback>
                ) : null}
              </div>
            </Col>


            <Col sm={6} className="col-xl align-self-end">
              <div className="mb-3">
                <Button
                  type="button"
                  color="primary"
                  className="w-md"
                  onClick={(e) => {
                    e.preventDefault()
                    filterRecords()
                  }}
                >
                  Filter
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Row>


    </React.Fragment>
  );
};

export default withRouter(AnalyticsFilter);
