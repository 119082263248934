/* eslint-disable no-underscore-dangle */
import { AbilityBuilder, createMongoAbility, defineAbility } from '@casl/ability';
import { getAuthenticatedUser } from 'api/user';


const updatePermissions = (user, can) => {
    user?.userPermissions && user?.userPermissions.map(item => {
        can('user', item)
    })
}


export function defineRulesFor(ability, user) {
    const { can, rules, cannot } = new AbilityBuilder(createMongoAbility)
    updatePermissions(user, can)
    return ability.update(rules);
}

export default defineAbility((can, cannot) => {
    const user = getAuthenticatedUser()
    updatePermissions(user, can)
});

export const defineAbilitiesFor = () => {
    const user = getAuthenticatedUser()
    const { can, build, cannot } = new AbilityBuilder(createMongoAbility)
    updatePermissions(user, can)
    return build();
}


export const checkPermissions = (permissions) => {
    const ability = defineAbilitiesFor()
    // true if ability allows the action
    return permissions.some(value => ability.can("user", value) === true)
}
