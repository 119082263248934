import PropTypes from "prop-types"
import React from "react"
import { Col, Card, CardBody, Row } from "reactstrap"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
const MiniWidget = ({ analyticsDetails }) => {
  const reports = analyticsDetails["reports"] || []

  return (
    <React.Fragment>
      {reports.map((report, key) => (
        <Col sm="3" key={key}>
          <Card outline color={report.color} className="border">
            <CardBody>
              <div className="d-flex align-items-center mb-3">
                <div className="avatar-xs me-3">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                    <i
                      className={"bx " + report.iconClass + " font-size-24"}
                    ></i>
                  </span>
                </div>
                <h5 className="font-size-14 mb-0">{report.title}</h5>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h4 className="mb-0">{report.description}</h4>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}

      <Row>
        <Col xl="8">
          <Card>
            <CardBody>
              <HighchartsReact
                highcharts={Highcharts}
                options={analyticsDetails?.analyticsOptions}
              />
            </CardBody>
          </Card>
        </Col>

        <Col xl="4">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-3">
                <div className="avatar-xs me-3">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                    <i className="bx bx-purchase-tag-alt font-size-24"></i>
                  </span>
                </div>
                <h5 className="font-size-14 mb-0">Sms Reports</h5>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h4 className="mb-0">
                    {analyticsDetails?.sms_report || 0}
                  </h4>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-3">
                <div className="avatar-xs me-3">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                    <i className="bx bx-purchase-tag-alt font-size-24"></i>
                  </span>
                </div>
                <h5 className="font-size-14 mb-0">Web Reports</h5>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h4 className="mb-0">
                    {analyticsDetails?.online_report || 0}
                  </h4>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-3">
                <div className="avatar-xs me-3">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                    <i className="bx bx-purchase-tag-alt font-size-24"></i>
                  </span>
                </div>
                <h5 className="font-size-14 mb-0">Bulk SMS</h5>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h4 className="mb-0"> {analyticsDetails?.bulk_sms || 0}</h4>
                </div>
              </div>
            </CardBody>
          </Card>
          {
           analyticsDetails?.organizations > 0 && 
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <i className="bx bx-purchase-tag-alt font-size-24"></i>
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">Organizations</h5>
                </div>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <h4 className="mb-0">
                      {analyticsDetails?.organizations || 0}
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}

MiniWidget.propTypes = {
  reports: PropTypes.array,
}

export default MiniWidget
