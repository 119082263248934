import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, SET_NOTIFICATIONS_COUNT, SET_NOTIFICATIONS } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  notificationCount: 0,
  notifications: [],
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    case SET_NOTIFICATIONS_COUNT:
      state = { ...state, notificationCount: action.payload };
      break;
    case SET_NOTIFICATIONS:
      state = { ...state, notifications: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
