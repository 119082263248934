import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { getAuthenticatedUser } from '../../../api/user';
// users

import avatarIcon from '../../../assets/images/avatar.avif'
import { changeLayoutMode, changeSidebarTheme, changeUserLogo } from "store/actions";
import { layoutModeTypes, leftSideBarThemeTypes } from "constants/layout";

import myLogo from "../../../assets/images/uwajibikaji.avif";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [userDetails, setuserDetails] = useState({});

  const navigate = useNavigate()
  const dispatch = useDispatch()


  useEffect(() => {
    const user = getAuthenticatedUser();
    setuserDetails(user);

  }, []);

  const logout = () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("myLogo");
    localStorage.removeItem("myMode");
    localStorage.removeItem("myTheme");

    dispatch(changeLayoutMode(layoutModeTypes.LIGHT));
    dispatch(changeSidebarTheme(leftSideBarThemeTypes.DARK));
    dispatch(changeUserLogo(myLogo));

    navigate('/login')
  }


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={avatarIcon}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{userDetails?.firstName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to='/profile' className="dropdown-item" >
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </Link>

          <div className="dropdown-divider" />

          <button onClick={logout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
