import React, { useState, useEffect } from "react"
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  FormFeedback,
  Collapse
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import { map, method } from "lodash"
import CardPricing from "../Utility/card-pricing"
import { useFormik } from "formik";
import * as Yup from "yup";
import { invokeApiCall } from '../../api';
import toastr from "toastr";
import parse from 'html-react-parser';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PayPalButtons } from "@paypal/react-paypal-js";
const Register = () => {
  const navigate = useNavigate();
  const showConfirmation = (title, message) => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes,Proceed'
      }).then((result) => {
        if (result.value) {
          resolve(true);
        }
        else {
          reject(false);
        }
      }, (err) => {
        reject(false);

      });



    });


  }

  //meta title
  document.title = "Create Account";
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [memberID, setmemberID] = useState("");
  const [selectedSubscription, setselectedSubscription] = useState("");
  const [invoiceDetails, setinvoiceDetails] = useState({});



  const [subscriptionTypes, setsubscriptionTypes] = useState([]);
  const [gotoNextStep, setgotoNextStep] = useState(false);

  const [selectedPaymentMenu, setselectedPaymentMenu] = useState("");
  const [transactionDetails, settransactionDetails] = useState(0);

  const selectPaymentMenu = (e, i) => {
    setselectedPaymentMenu(i);
  };
  const initialOptions = {
    "client-id": "ARpIQohdm-sZi3mVoHyVpV5BCGAigL1kMUyThBpdB6mcuXhLQ3-5qIqn_7xvAV_DQ6VsajbfCxeAs-sq",
    currency: "USD",
    intent: "capture",
  };
  const paymentMethods = [
    {
      "id": 1,
      "name": "Paypal",
      "icon": "fab fa-cc-paypal",
      "payment_steps": " <PayPalScriptProvider options={initialOptions}><Checkout/></PayPalScriptProvider>"
    }

  ];

  const onCreateOrder = (data, actions) => {
    setgotoNextStep(false);
    return actions.order.create({
      purchase_units: [
        {
          invoice_id: invoiceDetails.reference_number,
          amount: {
            value: invoiceDetails.outstanding_amount,
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: invoiceDetails.outstanding_amount
              }
            }
          },
          items: [{
            name: invoiceDetails.reference_number,
            quantity: '1',

            category: 'DIGITAL_GOODS',
            unit_amount: {
              currency_code: 'USD',
              value: invoiceDetails.outstanding_amount,
            },
          }]

        },
      ],
    });
  }

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      const transactionInfo = details;
      const payerDetails = transactionInfo?.payer;
      const purchaseDetails = transactionInfo?.purchase_units[0];
      const purchaseItems = purchaseDetails?.items[0];
      const paymentDetails = purchaseDetails?.payments;
      const paymentCaptures = paymentDetails?.captures[0];

      const payload = {
        user_id: payerDetails?.payer_id,
        payer_id: payerDetails?.payer_id,
        full_names: payerDetails?.name?.given_name + " " + payerDetails?.name?.surname,
        payment_status: transactionInfo?.status,
        invoice_id: paymentCaptures?.invoice_id,
        transaction_id: paymentCaptures?.id,
        transaction_date: paymentCaptures?.create_time,
        payment_status: paymentCaptures?.status,
        amount: purchaseItems?.unit_amount?.value,
        invoice_payload: details

      }
      settransactionDetails(payload);
      setgotoNextStep(true);

    });
  }

  const fetchSubscriptions = () => {
    invokeApiCall("FETCH_SUBSCRIPTIONS", {}).then((res) => {
      const results = res.data;
      setsubscriptionTypes(results.results)

    });
  }

  useEffect(() => {
  }, [memberID])

  useEffect(() => {
  }, [invoiceDetails])
  useEffect(() => {

    if (transactionDetails !== 0) {
      invokeApiCall("POST_PAYPAL_CONFIRMATION", transactionDetails).then((res) => {
        toastr.success("Transaction is complete");
        setgotoNextStep(true);
      });


    }

  }, [transactionDetails])

  useEffect(() => {
    if (gotoNextStep) {

      toggleTab(activeTab + 1)
    }
  }, [gotoNextStep])


  useEffect(() => {
    fetchSubscriptions()
  }, [])


  const selectSubscription = (e, i) => {
    setselectedSubscription(i);

  }


  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4
      ) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }
  const registerMember = (payload) => {
    invokeApiCall("MEMBER_REGISTRATION", payload).then((res) => {
      setmemberID(res.user_id);
      setgotoNextStep(true);


    })
  }

  const membershipValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      first_name: "",
      surname: "",
      phone_number: "",
      email: "",
      password: "",
      password_confirmation: "",
      address: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please enter your first name"),
      surname: Yup.string().required("Please enter your surname"),
      // other_names: Yup.string().required("Please Enter Your Password"),
      phone_number: Yup.string().required("Please enter your phone number"),
      email: Yup.string().required("Please enter your email"),
      password: Yup.string().required("Please enter your password"),
      password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
      address: Yup.string().required("Please enter your address"),
    }),
  });
  const validateMembershipStep = () => {
    const isDirty = membershipValidation.dirty;
    if (!isDirty) {
      membershipValidation.handleSubmit();
      toastr.error("Please correct the errors to proceed");
      return false
    }
    else {

      const memberInfo = membershipValidation.values;
      registerMember(memberInfo)
    }



  }
  const validateSubscriptionStep = () => {
    setgotoNextStep(false);
    if (selectedSubscription == "" || !selectedSubscription) {
      toastr.error("Please select subscription to continue");
    }
    else {
      // show popup confirming subscription and generate invoice
      showConfirmation("Confirmation", "Do you wish to proceed checking out?(This will generate an invoice)").then((res) => {
        if (res) {
          // api call
          const payload = {
            user_id: memberID,
            product: selectedSubscription,
            renew_automatically: false
          }
          invokeApiCall("GENERATE_SUBSCRIPTION_INVOICE", payload).then((res) => {

            setinvoiceDetails(res);
            setgotoNextStep(true);

          })
        }

      });

    }
  }
  const validatePaymentStep = () => {

  }








  return (
    <React.Fragment>
      <div className="page-content">
        <Container >
          <Row>
            <Col lg="12">
              <Card>

                <CardBody>
                  <h4 className="card-title mb-4">Create membership account</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            // onClick={() => {
                            //   setactiveTab(1)
                            // }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Member Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            // onClick={() => {
                            //   setactiveTab(2)
                            // }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Select membership type
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            // onClick={() => {
                            //   setactiveTab(3)
                            // }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> Payment details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            // onClick={() => {
                            //   setactiveTab(4)
                            // }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">4.</span> Confirmation page
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Form onSubmit={(e) => {
                            e.preventDefault();
                            membershipValidation.handleSubmit();
                            return false;
                          }}>
                            <Row>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    First name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    name="first_name"
                                    placeholder="Enter Your First Name"
                                    onChange={membershipValidation.handleChange}
                                    onBlur={membershipValidation.handleBlur}
                                    value={membershipValidation.values.first_name || ""}
                                    invalid={
                                      membershipValidation.touched.first_name && membershipValidation.errors.first_name ? true : false
                                    }
                                  />
                                  {membershipValidation.touched.first_name && membershipValidation.errors.first_name ? (
                                    <FormFeedback type="invalid">{membershipValidation.errors.first_name}</FormFeedback>
                                  ) : null}
                                </div>

                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Last name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-lastname-input2"
                                    placeholder="Enter Your Last Name"
                                    name="surname"
                                    onChange={membershipValidation.handleChange}
                                    onBlur={membershipValidation.handleBlur}
                                    value={membershipValidation.values.surname || ""}
                                    invalid={
                                      membershipValidation.touched.surname && membershipValidation.errors.surname ? true : false
                                    }
                                  />
                                  {membershipValidation.touched.surname && membershipValidation.errors.surname ? (
                                    <FormFeedback type="invalid">{membershipValidation.errors.surname}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="basicpill-othernames-input2">
                                    Other names
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-othernames-input2"
                                    placeholder="Enter other names"
                                    name="other_names"
                                    onChange={membershipValidation.handleChange}
                                    onBlur={membershipValidation.handleBlur}
                                    value={membershipValidation.values.other_names || ""}
                                    invalid={
                                      membershipValidation.touched.other_names && membershipValidation.errors.other_names ? true : false
                                    }
                                  />
                                  {membershipValidation.touched.other_names && membershipValidation.errors.other_names ? (
                                    <FormFeedback type="invalid">{membershipValidation.errors.other_names}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-phoneno-input3">
                                    Phone
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-phoneno-input3"
                                    placeholder="Enter Your Phone No."
                                    name="phone_number"
                                    onChange={membershipValidation.handleChange}
                                    onBlur={membershipValidation.handleBlur}
                                    value={membershipValidation.values.phone_number || ""}
                                    invalid={
                                      membershipValidation.touched.phone_number && membershipValidation.errors.phone_number ? true : false
                                    }
                                  />
                                  {membershipValidation.touched.phone_number && membershipValidation.errors.phone_number ? (
                                    <FormFeedback type="invalid">{membershipValidation.errors.phone_number}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-email-input4">
                                    Email
                                  </Label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    id="basicpill-email-input4"
                                    placeholder="Enter Your Email"
                                    name="email"
                                    onChange={membershipValidation.handleChange}
                                    onBlur={membershipValidation.handleBlur}
                                    value={membershipValidation.values.email || ""}
                                    invalid={
                                      membershipValidation.touched.email && membershipValidation.errors.email ? true : false
                                    }
                                  />
                                  {membershipValidation.touched.email && membershipValidation.errors.email ? (
                                    <FormFeedback type="invalid">{membershipValidation.errors.email}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-password-input3">
                                    Password
                                  </Label>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    id="basicpill-password-input3"
                                    placeholder="Enter Your Password"
                                    name="password"
                                    onChange={membershipValidation.handleChange}
                                    onBlur={membershipValidation.handleBlur}
                                    value={membershipValidation.values.password || ""}
                                    invalid={
                                      membershipValidation.touched.password && membershipValidation.errors.password ? true : false
                                    }
                                  />
                                  {membershipValidation.touched.password && membershipValidation.errors.password ? (
                                    <FormFeedback type="invalid">{membershipValidation.errors.password}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-repeat-password-input4">
                                    Repeat password
                                  </Label>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    id="basicpill-repeat-password-input4"
                                    placeholder="Repeat password"
                                    name="password_confirmation"
                                    onChange={membershipValidation.handleChange}
                                    onBlur={membershipValidation.handleBlur}
                                    value={membershipValidation.values.password_confirmation || ""}
                                    invalid={
                                      membershipValidation.touched.password_confirmation && membershipValidation.errors.password_confirmation ? true : false
                                    }
                                  />
                                  {membershipValidation.touched.password_confirmation && membershipValidation.errors.password_confirmation ? (
                                    <FormFeedback type="invalid">{membershipValidation.errors.password_confirmation}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="basicpill-address-input1">
                                    Address
                                  </Label>
                                  <Input
                                    id="basicpill-address-input1"
                                    className="form-control"
                                    type="textarea"
                                    rows="2"
                                    placeholder="Enter Your Address"
                                    name="address"
                                    onChange={membershipValidation.handleChange}
                                    onBlur={membershipValidation.handleBlur}
                                    value={membershipValidation.values.address || ""}
                                    invalid={
                                      membershipValidation.touched.address && membershipValidation.errors.address ? true : false
                                    }
                                  />
                                  {membershipValidation.touched.address && membershipValidation.errors.address ? (
                                    <FormFeedback type="invalid">{membershipValidation.errors.address}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>

                          <Row className="justify-content-center">
                            <Col lg={6}>
                              <div className="text-center mb-5">
                                <h4>Choose your Pricing plan</h4>
                                <p className="text-muted">
                                  Select you preferred membership type below
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Row>

                            {subscriptionTypes.map((subscription, index) => (
                              <Col xl="6" md="6" key={subscription.id}>
                                <Card className="plan-box border " outline color={selectedSubscription === subscription.id ? 'success' : null} >
                                  <CardBody className="p-4">
                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <h5 className={selectedSubscription === subscription.id ? 'text-success' : null}>{subscription?.title}</h5>

                                        <p className="text-muted">{subscription?.name}</p>
                                      </div>
                                      <div className="flex-shrink-0 ms-3">
                                        <i
                                          className={"bx " + subscription?.icon + " h1 text-primary"} style={{ lineHeight: 1 }}
                                        />
                                      </div>
                                    </div>
                                    <div className="py-4">
                                      <h2>
                                        {/* <sup>
                                          <small>$</small>
                                        </sup>{" "} */}
                                        {subscription?.amount}
                                        <span className="font-size-13">Per year</span>
                                      </h2>
                                    </div>
                                    <div className="text-center plan-btn">
                                      <button
                                        onClick={(e) => { selectSubscription(e, subscription?.id) }}
                                        className="btn btn-primary btn-sm waves-effect waves-light"
                                      >
                                        Select
                                      </button>
                                    </div>
                                    {/* 
                                    <div className="plan-features mt-5">
                                      {pricing.features.map((feature, key) => (
                                        <p key={"_feature_" + key}>
                                          <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                                          {feature.title}
                                        </p>
                                      ))}
                                    </div> */}
                                  </CardBody>
                                </Card>
                              </Col>

                              // <CardPricing pricing={pricing} key={"_pricing_" + key} />



                            ))}
                          </Row>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div>
                            <CardTitle>Select payment method</CardTitle>




                            <Row>
                              {paymentMethods.map((method, index) => (
                                <div className="accordion" id="accordion" key={method.id} >
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button
                                        className={classnames(
                                          "accordion-button",
                                          "fw-medium",
                                          { collapsed: selectedPaymentMenu === method.id ? true : false }
                                        )}
                                        type="button"
                                        onClick={(e) => { selectPaymentMenu(e, method.id) }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className={method.icon + " me-1 font-size-20 align-top"} />   {method.name}
                                      </button>
                                    </h2>

                                    <Collapse className="accordion-collapse" isOpen={selectedPaymentMenu === method.id ? true : false}>

                                      <div className="accordion-body">
                                        <div className="text-muted">
                                          Choose your payment option to proceed

                                          <PayPalScriptProvider options={initialOptions}>

                                            <PayPalButtons
                                              forceReRender={[invoiceDetails]}
                                              color="primary"
                                              className="btn"
                                              data-toggle="button"
                                              aria-pressed="false"
                                              style={{ layout: "vertical" }}
                                              createOrder={(data, actions) => {
                                                return onCreateOrder(data, actions)
                                              }}
                                              onApprove={(data, actions) => {

                                                return onApproveOrder(data, actions)
                                              }}
                                              onError={() => {
                                                toastr.error("Sorry, your transaction could not be completed at this time.Please try again later")
                                              }}
                                            />
                                          </PayPalScriptProvider>
                                          {/* <span key={index}>{parse(method.payment_steps)}</span> */}
                                          {/* {method.payment_steps.map((step, index) => (
                                            <span key={index}>{parse(step)}</span>
                                          ))} */}




                                        </div>
                                      </div>
                                    </Collapse>
                                  </div>
                                </div>
                              ))}
                            </Row>





                          </div>

                        </TabPane>






                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Registration successful</h5>

                                  We've received your membership registration.Our support agents will reach out to you on the next steps.Thankyou
                                  <div className="mt-5 text-center">
                                    <p>

                                      <Link to="/login" className="fw-medium text-primary">
                                        {" "}
                                        You can Login here{" "}
                                      </Link>{" "}
                                    </p>

                                  </div>

                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        {/* <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {

                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li> */}
                        <li
                          className={activeTab === 4 ? "next disabled" : "next"}
                        >
                          <Link

                            onClick={(e) => {
                              // e.preventDefault();
                              const tabValidationsteps = {
                                1: validateMembershipStep,
                                2: validateSubscriptionStep,
                                3: validatePaymentStep
                              }
                              const nextFunction = tabValidationsteps[activeTab];
                              nextFunction();

                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Login now{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Pamca. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> for Pamca
                </p>
              </div>
            </Col>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
