import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import store from "./store";
import { AbilityContext } from "permissions/Can";
import ability from "permissions/ability";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AbilityContext.Provider value={ability}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AbilityContext.Provider >
  </Provider>
);

serviceWorker.unregister()
