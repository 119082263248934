import PropTypes from 'prop-types'
import React from "react"
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"


const CustomModal = (props) => {
  return (
    <Modal size={props.size} isOpen={props.show} toggle={props.onCloseClick} centered={true}>
      <ModalHeader>
        {props.title}
      </ModalHeader>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          {props.children}
        </ModalBody>
      </div>
      <ModalFooter>
        <button type="button" className="btn btn-secondary" onClick={props.onCloseClick}>Close</button>
        {props.addSubmitButton &&
          <button type="submit" className="btn btn-primary" onClick={props.onActionSubmit}>Submit</button>
        }
      </ModalFooter>
    </Modal>
  )
}

CustomModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any
}

export default CustomModal
