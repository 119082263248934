import React, { useEffect, useState } from "react"
import withRouter from "components/Common/withRouter"
import { invokeApiCall } from "../../api"
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { useFormik } from "formik"

import {
  formatDate,
} from "../../templates/tableCol"
import * as XLSX from 'xlsx';
import swal from "sweetalert"

const ReportFilter = ({ fetchFilteredRecords, reportType, records }) => {
  const [ReportTypes, setReportTypes] = useState([])
  const [Regions, setRegions] = useState([])
  const [organizations, setOrganizations] = useState([])

  const formValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      region_id: null,
      report_id: null,
      organization_id: null,
      report_date: null,
      report_date_to: null,
      report_from__icontains: null,
      sf_issue_number: null
    },
  })

  const fetchRecords = params => {
    invokeApiCall("FETCH_INCOMING_REPORTS", params).then(res => {
      const results = res.data
      const responseDetails = {
        response_data: results,
        ...params,
      }
      fetchFilteredRecords(responseDetails)
    })
  }
  const filterRecords = () => {
    const filterPayload = {
      "report_type": reportType, ...formValidation.values
    }
    fetchRecords(filterPayload)
  }
  const fetchreportTypes = params => {
    invokeApiCall("FETCH_REPORT_TYPES", params).then(res => {
      const results = res.data
      setReportTypes(results.results)
    })
  }
  const fetchRegions = params => {
    invokeApiCall("FETCH_REGIONS", params).then(res => {
      const results = res.data
      setRegions(results.results)
    })
  }

  const fetchOrganizations = params => {
    invokeApiCall("FETCH_ORGANIZATIONS", params).then(res => {
      const results = res.data
      setOrganizations(results.results)
    })
   }
  
  useEffect(() => {
    const filterParams = {
      page_size: 1000,
    }
    fetchreportTypes(filterParams)
    fetchRegions(filterParams)
    fetchOrganizations(filterParams)
  }, [])

  const fetchExportData = () => {
    const workbook = XLSX.utils.book_new();
    
    const fetchPage = (filterPayload) => {
      invokeApiCall("EXPORT_FETCH_INCOMING_REPORTS", filterPayload)
        .then(res => {
          const resp = res.data;
          if (resp?.results?.length) {
            const records = resp.results;
            const data = records.map((record) => {
              const responses = record.incoming_responses.reduce((acc, response) => {
                acc[response.quiz] = response.option || response.input_text;
                return acc;
              }, {});

              const enquiries = record.enquiries.map((enquiry) => (
                `${enquiry.enquiry_type}: ${enquiry.input_text}`
              ));

              return {
                "Reference Number": record.reference_number,
                "Report": record.input_text,
                "Status": record.status,
                "Report From": record.report_from,
                "Platform": record.platform,
                "Organization": record.organization,
                "Sector": record.sector,
                "Region": record.region,
                "Date Reported": formatDate(record.created_at, "DD MMM, yyyy"),
                ...responses,
                "Enquiries": enquiries.join("\n"),
              };
            });
            const page = filterPayload.page
            const worksheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, worksheet, `Sheet${page}`);

            if (resp?.next) {
              filterPayload.page = page + 1 ; // Update the page number
              fetchPage(filterPayload);  // Recursively fetch the next page
            } else {
              console.log("Completed fetching all pages");
              finalizeWorkbook();
            }
          } else {
            console.log("No results found");
            finalizeWorkbook();
          }
        })
        .catch(error => {
          console.error("Error fetching reports:", error);
          finalizeWorkbook();
        });
    };

    const finalizeWorkbook = () => {
      if (workbook.Sheets?.Sheet1) {
        XLSX.writeFile(workbook, "Report List.xlsx");
      } else {
        swal({
          title: "No record to export",
          icon: "info",
        });
      }
    };

    // Initial filter payload
    const filterPayload = {
      report_type: reportType,
      ...formValidation.values,
      page_size: 100,
      page: 1
    };

    fetchPage(filterPayload); // Start fetching data
  };


  return (
    <React.Fragment>
      <Row>
        <Form
          onSubmit={e => {
            e.preventDefault();
            formValidation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-email-input4">Phone number</Label>
                <Input
                  type={"tel"}
                  className="form-control"
                  id="basicpill-email-input4"
                  placeholder="Enter phone number"
                  name="report_from__icontains"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.report_from__icontains || ''}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-email-input4">Organization</Label>
                <Input
                  type={"select"}
                  className="form-control"
                  id="basicpill-email-input4"
                  placeholder="Select region"
                  name="organization_id"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.organization_id || ''}
                >
                  <option value={""} disabled hidden></option>
                  {organizations?.length &&
                    organizations?.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Input>
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-email-input4">Region</Label>
                <Input
                  type={"select"}
                  className="form-control"
                  id="basicpill-email-input4"
                  placeholder="Select region"
                  name="region_id"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.region_id || ''}
                >
                  <option value={""} disabled hidden></option>
                  {Regions?.length &&
                    Regions?.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Input>
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-email-input4">Report Category</Label>
                <Input
                  type={"select"}
                  className="form-control"
                  id="basicpill-email-input4"
                  placeholder="Select report category"
                  name="report_id"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.report_id || ''}
                >
                  <option value={""} disabled hidden></option>
                  {ReportTypes?.length &&
                    ReportTypes?.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Input>
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-email-input4">Salesforce number</Label>
                <Input
                  type={"text"}
                  className="form-control"
                  placeholder="Enter Salesforce number"
                  name="sf_issue_number"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.sf_issue_number || ''}
                />
              </div>
            </Col>
            <Col lg="3" className="col-xl">
              <FormGroup className="mb-3">
                <Label className="form-label">Date From</Label>
                <Input
                  type={"date"}
                  selected={formValidation.values.report_date}
                  name="report_date"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.report_date || ''}
                  className="form-control"
                />
              </FormGroup>
            </Col>
            <Col lg="3" className="col-xl">
              <FormGroup className="mb-3">
                <Label className="form-label">Date To</Label>
                <Input
                  type={"date"}
                  selected={formValidation.values.report_date_to}
                  name="report_date_to"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.report_date_to || ''}
                  className="form-control"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm="2" className="align-self-center mt-2">
              <Button
                type="button"
                color="primary"
                className="w-md"
                onClick={e => {
                  e.preventDefault()
                  filterRecords()
                }}
              >
                Filter
              </Button>
            </Col>
            <Col sm="2" className="align-self-center mt-2">
              <Button
                type="button"
                color="primary"
                className="w-md"
                onClick={() => fetchExportData()}
              >
                Export
              </Button>
            </Col>
            <Col sm="2" className="align-self-center mt-2">
              <Button
                type="button"
                color="warning"
                className="w-md"
                onClick={e => {
                  e.preventDefault()
                  formValidation.resetForm()
                  fetchRecords({"report_type": reportType})
                }}
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(ReportFilter)
