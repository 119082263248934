import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  CardTitle,
  Badge,
  Label,
  Input,
  Form,
  FormFeedback,
  Button,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { invokeApiCall } from "../../api"
import { map } from "lodash"
import { useFormik } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import { useNavigate } from "react-router-dom"

const ManualReport = props => {
  //meta title
  document.title = "Add Report"
  const [Questions, setQuestions] = useState([])

  const [responses, setResponses] = useState({})
  const [reportTypes, setReportTypes] = useState([])

  const navigate = useNavigate()

  const addReportResponse = e => {
    if (e.target.value) {
      setResponses({
        ...responses,
        [e.target.name]: e.target.value,
      })
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      report: "",
      phone_number: "",
      report_type: "",
      // region_id: "",
    },
    validationSchema: Yup.object({
      report: Yup.string().required("Enter report"),
      phone_number: Yup.string().required("Enter phone number"),
      report_type: Yup.string().required("Enter report type"),
      // region_id: Yup.string().required("Enter region"),
      responded: Yup.bool().when([], {
        is: () => Object.keys(responses).length < Questions?.length === true,
        then: Yup.bool().required("Please answer all the questions"),
      }),
    }),
    onSubmit: payload => {
      payload["responses"] = responses
      submitReport(payload)
    },
  })

  const submitReport = payload => {
    invokeApiCall("SUBMIT_MANUAL_REPORT", payload).then(res => {
      if (res) {
        toastr.success("Success")
        navigate(`/report/${res?.id}`)
      }
    })
  }
  const fetchQuestions = payload => {
    invokeApiCall("FETCH_REPORT_QUESTIONS", payload).then(res => {
      const results = res.data
      setQuestions(results)
    })
  }

  const fetchReportTypes = payload => {
    invokeApiCall("FETCH_REPORT_TYPES", payload).then(res => {
      const results = res.data
      setReportTypes(results.results)
    })
  }

  useEffect(() => {
    fetchQuestions({})
    fetchReportTypes({})
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Report" breadcrumbItem="Add report" />

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="12">
                        <Label className="col-form-label col-lg-2">
                          Report
                        </Label>
                        <Input
                          rows={5}
                          name="report"
                          type="textarea"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.report || ""}
                          invalid={
                            validation.touched.report &&
                              validation.errors.report
                              ? true
                              : false
                          }
                        />
                        {validation.touched.report &&
                          validation.errors.report ? (
                          <FormFeedback type="invalid">
                            {validation.errors.report}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <Label className="col-form-label">
                          Report Type
                        </Label>
                        <Input
                          name="report_type"
                          type="select"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.report_type || ""}
                          invalid={
                            validation.touched.report_type &&
                              validation.errors.report_type
                              ? true
                              : false
                          }
                        >
                          <option value="">--------</option>
                          {reportTypes?.length &&
                            reportTypes?.map((region, index) => (
                              <option key={index} value={region.id}>
                                {region.name}
                              </option>
                            ))}
                        </Input>
                        {validation.touched.report_type &&
                          validation.errors.report_type ? (
                          <FormFeedback type="invalid">
                            {validation.errors.report_type}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md="6">
                        <Label className="col-form-label">Phone Number</Label>
                        <Input
                          name="phone_number"
                          type="text"
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phone_number || ""}
                          invalid={
                            validation.touched.phone_number &&
                              validation.errors.phone_number
                              ? true
                              : false
                          }
                        />
                        {validation.touched.phone_number &&
                          validation.errors.phone_number ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phone_number}
                          </FormFeedback>
                        ) : null}
                      </Col>
                    </Row>
                    <br></br>

                    <CardTitle className="mb-4">
                      Questions
                      {validation.errors.responded && (
                        <small
                          style={{
                            color: "#F46969",
                            paddingLeft: "2px",
                          }}
                        >
                          ({validation.errors.responded})
                        </small>
                      )}
                    </CardTitle>
                    <div className="table-responsive">
                      <Table className="table-wrap align-middle table-hover mb-0">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "100px" }}>
                              #
                            </th>
                            <th scope="col">Question</th>
                            <th scope="col">Answer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(Questions, (record, index) => (
                            <tr key={index}>
                              <td> {index + 1}</td>
                              <td> {record.question}</td>
                              <td>
                                {
                                  <>
                                    {record?.question_options?.length < 1 ? (
                                      <Input
                                        onChange={addReportResponse}
                                        value={responses[record.id]}
                                        name={record.id}
                                        type="text"
                                      />
                                    ) : (
                                      <Input
                                        onChange={addReportResponse}
                                        value={responses[record.id]}
                                        name={record.id}
                                        type="select"
                                      >
                                        <option value="" defaultChecked>
                                          ------
                                        </option>

                                        {record?.question_options?.map(
                                          (item, index) => (
                                            <option
                                              key={index}
                                              value={item.option}
                                            >
                                              {item.option}
                                            </option>
                                          )
                                        )}
                                      </Input>
                                    )}
                                  </>
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <br></br>
                    <Row>
                      <Col>
                        <Button type="submit" color="primary">
                          Submit report
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ManualReport.propTypes = {
  match: PropTypes.object,
}

export default withRouter(ManualReport)
