import React from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"

const formatDate = (input, format) => {
  const dateFormat = format ? format : "DD MMM Y HH:mm:SS"
  const date1 = moment(new Date(input)).format(dateFormat)
  return date1
}
const formatWorkflowNode = str => {
  const capitalized = str.slice(0, 1).toUpperCase() + str.slice(1, str.length)
  return capitalized.replace(/_/g, " ")
}
const toLowerCase1 = str => {
  return str === "" || str === undefined ? "" : str.toLowerCase()
}

const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell.value ? cell.value : ""}
    </Link>
  )
}

const DefaultFormat = cell => {
  return cell.value ? cell.value : ""
}

const BooleanFormat = cell => {
  switch (cell.value) {
    case true:
      return <Badge className="bg-success"> Yes </Badge>
    case false:
      return <Badge className="bg-warning"> No </Badge>

    default:
      return <Badge className="bg-warning"> No </Badge>
  }
}

// const Date = (cell) => {
//     return cell.value ? cell.value : '';
// };

const Total = cell => {
  return cell.value ? cell.value : ""
}

const PaymentStatus = ({ status, record }) => {
  switch (status) {
    case "PENDING":
      return (
        <Link
          to={{
            pathname: `/invoice-checkout/${record.id}`,
          }}
          className="btn btn-success btn-sm w-xs"
        >
          Pay now
        </Link>
      )

    case "COMPLETED":
      return ""

    case "REJECTED":
      return <Badge className="bg-danger"> {status} </Badge>

    default:
      return ""
  }
}

const ApplicationStatus = cell => {
  switch (cell.value) {
    case "ONGOING":
      return <Badge className="bg-warning"> Ongoing </Badge>
    case "COMPLETED":
      return <Badge className="bg-success"> Solved </Badge>
    case "REJECTED":
      return <Badge className="bg-danger"> Rejected </Badge>
    case "BANNED":
      return <Badge className="bg-danger"> Banned </Badge>
    case "DISMISSED":
      return <Badge className="bg-danger"> Dismissed </Badge>

    default:
      return <Badge className="bg-warning"> Draft </Badge>
  }
}

const InvoiceStatus = cell => {
  switch (cell.value) {
    case "ONGOING":
      return <Badge className="bg-warning"> {cell.value} </Badge>

    case "COMPLETED":
      return <Badge className="bg-success"> APPROVED </Badge>

    case "REJECTED":
      return <Badge className="bg-danger"> {cell.value} </Badge>

    default:
      return <Badge className="bg-warning">{cell.value} </Badge>
  }
}

const EventTicketStatus = cell => {
  switch (cell.value) {
    case "PENDING":
      return <Badge className="bg-warning"> {cell.value} </Badge>

    case "CONFIRMED":
      return <Badge className="bg-success"> {cell.value} </Badge>

    case "CHECKED_IN":
      return <Badge className="bg-success"> {cell.value} </Badge>

    case "UNKNOWN":
      return <Badge className="bg-danger"> {cell.value} </Badge>

    case "CANCELLED":
      return <Badge className="bg-danger"> {cell.value} </Badge>

    default:
      return <Badge className="bg-warning">{cell.value} </Badge>
  }
}

const DateFormatter = cell => {
  return formatDate(cell.value)
}

const SubscriberStatus = cell => {
  switch (cell.value) {
    case true:
      return <Badge className="bg-danger"> BLOCKED </Badge>
    default:
      return <Badge className="bg-success"> ACTIVE </Badge>
  }
}

export {
  CheckBox,
  OrderId,
  DefaultFormat,
  DateFormatter,
  Total,
  PaymentStatus,
  InvoiceStatus,
  ApplicationStatus,
  EventTicketStatus,
  formatDate,
  formatWorkflowNode,
  SubscriberStatus,
  BooleanFormat,
}
