import React, { useEffect, useState, useMemo } from "react"
import withRouter from "components/Common/withRouter"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  initiateSwalPrompt,
  invokeApiCall,
  invokeUpdateApiCall,
} from "../../api"
import {
  Col,
  Container,
  Card,
  CardBody,
  Row,
  Label,
  Form,
  FormFeedback,
  Input,
  UncontrolledTooltip,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { OrderId } from "../../templates/tableCol"
import TableContainer from "../../components/Common/TableContainer"
import CustomModal from "../../components/Common/CustomModal"
import { Can } from "permissions/Can"

import { checkPermissions } from "permissions/ability"
import { getAuthenticatedUser } from "api/user"
import toastr from "toastr"
import Pagination from "components/Common/Pagination"
import UserFilter from "./UserFilter"

const convener = "CONVENER"
const admin = "ADMIN"
const organization = "ORGANIZATION"

const UserList = () => {
  //meta title
  document.title = "Regions | regions"
  const [record, setRecord] = useState(null)
  const [records, setRecords] = useState([])
  const [showModal, setshowModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [regions, setRegions] = useState([])
  const [organizations, setOrganizations] = useState([])
  const canAddOrganization = checkPermissions(["can_create_organization"])
  const user = getAuthenticatedUser()
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationParams, setpaginationParams] = useState({
    totalRecords: 0,
    currentPage: 1,
  })

  const membershipValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      add_organization: canAddOrganization,
      first_name: record?.first_name ?? "",
      last_name: record?.surname ?? "",
      phone_number: record?.phone_number ?? "",
      email: record?.email ?? "",
      account_type: isEdit ? record?.account_type ?? "" : user?.userRoles?.includes(organization) ? organization : "",
      region: record?.regions ?? "",
      organization: record?.organization_id ?? "",
    },
    validationSchema: Yup.object({
      add_organization: Yup.boolean(),
      first_name: Yup.string().required("Please enter the first name"),
      last_name: Yup.string().required("Please enter the last name"),
      phone_number: Yup.string()
        .min(10, "Please enter valid phone number")
        .max(13, "Please enter valid phone number")
        .required("Please enter the phone number"),
      email: Yup.string().required("Please enter the email"),
      account_type: Yup.string().when("add_organization", {
        is: canAddOrganization && !isEdit,
        then: Yup.string().required("Please enter the account type"),
      }),
      // region: Yup.string().when("account_type", {
      //   is: !isEdit && canAddOrganization && convener,
      //   then: Yup.string().required("Please enter the respective region"),
      // }),
      organization: Yup.string().when("account_type", {
        is: organization && !isEdit && canAddOrganization,
        then: Yup.string().required("Please enter the respective organization"),
      }),
    }),
    onSubmit: values => {
      if (isEdit) {
        var updateRecord = {
          phone_number: values.phone_number,
          first_name: values.first_name,
          surname: values.last_name,
          organization: values.organization,
          // region: values.region,
        }

        updateEdittedRecord(updateRecord, record?.id)
      } else {
        const newRecord = {
          email: values.email,
          phone_number: values.phone_number,
          first_name: values.first_name,
          surname: values.last_name,
          account_type: values.account_type,
          // region: values.region,
          organization: values.organization,
        }
        createStaffRecord(newRecord)
      }
    },
  })

  const handleDeleteEvent = () => {
    toggle()
  }

  const fetchRecords = params => {
    invokeApiCall("FETCH_USERS", params).then(res => {
      const results = res.data
      setpaginationParams({
        totalRecords: results?.count,
        filterParams: params,
      })
      setCurrentPage(params?.page || currentPage)
      setRecords(results.results)
    })
  }

  const createRecord = () => {
    setIsEdit(false)
    setshowModal(true)
  }

  const editRecord = id => {
    const params = id
    // fetch details & popup
    invokeApiCall("FETCH_USER_DETAIL", params).then(res => {
      const results = res.data
      handleUserClick(results)
    })
  }

  const updateEdittedRecord = (payload, pk) => {
    invokeUpdateApiCall("UPDATE_USER", payload, pk).then(res => {
      const results = res.data
      setshowModal(false)
      fetchRecords()
    })
  }

  const removeRecord = payload => {
    initiateSwalPrompt().then(value => {
      if (value) {
        invokeUpdateApiCall("DELETE_USER", {}, payload).then(res => {
          fetchRecords()
        })
      }
    })
  }

  const handleUserClick = async arg => {
    setIsEdit(true)
    const details = arg
    if (details?.account_type === convener) {
      fetchRegions()
    } else if (details?.account_type === organization) {
      fetchOrganizations()
    }

    membershipValidation.setValues({
      first_name: details.first_name,
      last_name: details.surname,
      phone_number: details.phone_number,
      email: details.email,
      account_type: details.account_type,
      region: details?.regions ?? "",
      // organization: details?.organization,
      organization: details?.organization_id,
    })
      .then(() => {
        membershipValidation.setFieldTouched("region", false, false)
        membershipValidation.setFieldTouched("organization", false, false)

        setRecord(details)
        setshowModal(true)
      })

  }

  useEffect(() => {
    fetchRecords()
  }, [])

  const fetchOrganizations = params => {
    invokeApiCall("FETCH_ORGANIZATIONS", params).then(res => {
      const results = res?.data
      setOrganizations(results.results)
    })
  }

  const fetchPaginated = page => {
    const paginationParams = {
      page: page,
    }
    fetchRecords(paginationParams)
  }

  const fetchRegions = params => {
    invokeApiCall("FETCH_REGIONS", params).then(res => {
      const results = res?.data
      setRegions(results.results)
    })
  }

  const createStaffRecord = payload => {
    invokeApiCall("CREATE_STAFF", payload).then(res => {
      if (res) {
        setshowModal(false)
        fetchRecords()
      }
    })
  }

  const resetPassword = payload => {
    initiateSwalPrompt().then(value => {
      if (value) {
        invokeApiCall("RESET_USER_PASSWORD", payload).then(res => {
          if (res) {
            toastr.success("Success")
            fetchRecords()
          }
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Phone number",
        accessor: "phone_number",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Role",
        accessor: "roles",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div className="d-flex gap-3">
              <Can I="user" a="can_update_user">
                <Button
                  type="button"
                  color={"info"}
                  className="btn-sm btn-rounded"
                  onClick={e => {
                    e.preventDefault()
                    resetPassword({
                      email: row.original.email,
                    })
                  }}
                >
                  <i
                    className="mdi mdi-update font-size-18"
                    id="resettooltip"
                  />
                  <UncontrolledTooltip placement="top" target="resettooltip">
                    Reset Password
                  </UncontrolledTooltip>
                </Button>
              </Can>

              <Button
                type="button"
                color="success"
                className="btn-sm btn-rounded"
                onClick={e => {
                  e.preventDefault()
                  editRecord(row.original.id)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Button>

              <Can I="user" a="can_delete_user">
                <Button
                  type="button"
                  color={"danger"}
                  className="btn-sm btn-rounded"
                  onClick={e => {
                    e.preventDefault()
                    removeRecord(row.original.id)
                  }}
                >
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                  />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Button>
              </Can>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <CustomModal
        show={showModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => {
          membershipValidation.resetForm()
          setshowModal(false)
        }}
        addSubmitButton
        onActionSubmit={() => {
          membershipValidation.handleSubmit()
        }}
        size="xl"
        title="New user details"
      >
        <Form
          className="text-start"
          onSubmit={e => {
            // e.preventDefault();
            membershipValidation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col lg="4">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">First name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  name="first_name"
                  placeholder="Enter the First Name"
                  onChange={membershipValidation.handleChange}
                  onBlur={membershipValidation.handleBlur}
                  value={membershipValidation.values.first_name || ""}
                  invalid={
                    membershipValidation.touched.first_name &&
                      membershipValidation.errors.first_name
                      ? true
                      : false
                  }
                />
                {membershipValidation.touched.first_name &&
                  membershipValidation.errors.first_name ? (
                  <FormFeedback type="invalid">
                    {membershipValidation.errors.first_name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Last name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-lastname-input2"
                  placeholder="Enter the Last Name"
                  name="last_name"
                  onChange={membershipValidation.handleChange}
                  onBlur={membershipValidation.handleBlur}
                  value={membershipValidation.values.last_name || ""}
                  invalid={
                    membershipValidation.touched.last_name &&
                      membershipValidation.errors.last_name
                      ? true
                      : false
                  }
                />
                {membershipValidation.touched.last_name &&
                  membershipValidation.errors.last_name ? (
                  <FormFeedback type="invalid">
                    {membershipValidation.errors.last_name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Label for="basicpill-phoneno-input3">Phone Number</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-phoneno-input3"
                  placeholder="Enter the Phone No."
                  name="phone_number"
                  onChange={membershipValidation.handleChange}
                  onBlur={membershipValidation.handleBlur}
                  value={membershipValidation.values.phone_number || ""}
                  invalid={
                    membershipValidation.touched.phone_number &&
                      membershipValidation.errors.phone_number
                      ? true
                      : false
                  }
                />
                {membershipValidation.touched.phone_number &&
                  membershipValidation.errors.phone_number ? (
                  <FormFeedback type="invalid">
                    {membershipValidation.errors.phone_number}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <div className="mb-3">
                <Label for="basicpill-email-input4">Email</Label>
                <Input
                  disabled={isEdit}
                  type="email"
                  className="form-control"
                  id="basicpill-email-input4"
                  placeholder="Enter the Email"
                  name="email"
                  onChange={membershipValidation.handleChange}
                  onBlur={membershipValidation.handleBlur}
                  value={membershipValidation.values.email || ""}
                  invalid={
                    membershipValidation.touched.email &&
                      membershipValidation.errors.email
                      ? true
                      : false
                  }
                />
                {membershipValidation.touched.email &&
                  membershipValidation.errors.email ? (
                  <FormFeedback type="invalid">
                    {membershipValidation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Can I="user" a="can_create_organization">
              <Col lg="4">
                <div className="mb-3">
                  <Label for="basicpill-email-input4">Account Type</Label>
                  <Input
                    disabled={isEdit}
                    type={"select"}
                    className="form-control"
                    id="basicpill-email-input4"
                    placeholder="Enter the account type"
                    name="account_type"
                    onChange={e => {
                      const targetValue = e.target.value
                      if (targetValue === convener && !regions.length) {
                        fetchRegions()
                      } else if (
                        targetValue === organization &&
                        !organizations.length
                      ) {
                        fetchOrganizations()
                      }
                      membershipValidation.handleChange(e)
                    }}
                    onBlur={membershipValidation.handleBlur}
                    value={membershipValidation.values.account_type || ""}
                    invalid={
                      membershipValidation.touched.account_type &&
                        membershipValidation.errors.account_type
                        ? true
                        : false
                    }
                  >
                    <option value={""} disabled hidden></option>
                    <option value={organization}>{organization}</option>
                    <option value={convener}>{convener}</option>
                    <option value={admin}>{admin}</option>
                  </Input>
                  {membershipValidation.touched.account_type &&
                    membershipValidation.errors.account_type ? (
                    <FormFeedback type="invalid">
                      {membershipValidation.errors.account_type}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              {/* {membershipValidation.values.account_type == convener && (
                <Col lg="4">
                  <div className="mb-3">
                    <Label for="basicpill-email-input4">Region</Label>
                    <Input
                      type={"select"}
                      className="form-control"
                      id="basicpill-email-input4"
                      placeholder="Enter the account type"
                      name="region"
                      onChange={membershipValidation.handleChange}
                      onBlur={membershipValidation.handleBlur}
                      value={membershipValidation.values.region || ""}
                      invalid={
                        membershipValidation.touched.region &&
                          membershipValidation.errors.region
                          ? true
                          : false
                      }
                    >
                      <option value={""} disabled hidden></option>
                      {regions?.length &&
                        regions?.map((item, i) => (
                          <option key={i} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </Input>
                    {membershipValidation.touched.region &&
                      membershipValidation.errors.region ? (
                      <FormFeedback type="invalid">
                        {membershipValidation.errors.region}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              )} */}
              {membershipValidation.values.account_type == organization && (
                <Col lg="4">
                  <div className="mb-3">
                    <Label for="basicpill-email-input4">Organization</Label>
                    <Input
                      type={"select"}
                      className="form-control"
                      id="basicpill-email-input4"
                      placeholder="Enter the respective organization"
                      name="organization"
                      onChange={membershipValidation.handleChange}
                      onBlur={membershipValidation.handleBlur}
                      value={membershipValidation.values.organization || ""}
                      invalid={
                        membershipValidation.touched.organization &&
                          membershipValidation.errors.organization
                          ? true
                          : false
                      }
                    >
                      <option value={""} disabled hidden></option>
                      {organizations?.length &&
                        organizations?.map((item, i) => (
                          <option key={i} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </Input>
                    {membershipValidation.touched.organization &&
                      membershipValidation.errors.organization ? (
                      <FormFeedback type="invalid">
                        {membershipValidation.errors.organization}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              )}
            </Can>
          </Row>
        </Form>
      </CustomModal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="users" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Can I="user" a="can_create_user">
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                        onClick={createRecord}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Add new user
                      </Button>
                    </div>
                  </Can>
                  <div className="mb-4 h4 card-title">All records</div>

                  <UserFilter fetchRecords={fetchRecords} />
                  <TableContainer
                    columns={columns}
                    data={records}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={10}
                  />
                  <Pagination
                    totalRows={paginationParams.totalRecords}
                    pageChangeHandler={fetchPaginated}
                    rowsPerPage={10}
                    currentPage={currentPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserList)
