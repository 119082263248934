import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "../pages/Authentication/ResetPassword"

import ReportList from "../pages/Reports/ReportList"
import ReportDetail from "../pages/Reports/ReportDetail"
import SearchReport from "../pages/Reports/SearchReport"
import NewReport from "../pages/Reports/NewReport"
import ReferredReport from "../pages/Reports/ReferredReport"
import SolvedReport from "../pages/Reports/SolvedReport"
import DismissedReport from "../pages/Reports/DismissedReport"
import BannedReport from "../pages/Reports/BannedReport"
import ManualReport from "../pages/Reports/ManualReport"

import SubscriberList from "../pages/Subscribers/SubscriberList"
import QuestionList from "../pages/Configurations/QuestionList"

import Dashboard from "../pages/Dashboard/index"

import RegionList from "../pages/Configurations/RegionList"
import LanguageList from "../pages/Configurations/LanguageList"
import ReportTypeList from "../pages/Configurations/ReportTypeList"
import BlackListedWords from "../pages/Configurations/BlackListedWords"
import OrganizationList from "../pages/UserManagement/OrganizationList"
import UserList from "../pages/UserManagement/UserList"
import DeveloperUserList from "../pages/UserManagement/DeveloperUserList"
import ArchivedReport from "pages/Reports/ArchivedReport"
import { checkPermissions } from "permissions/ability"

import StatusDashboard from "pages/Analytics/StatusDashboard"
import RegionsDashboard from "pages/Analytics/RegionsDashboard"
import OrganizationsDashboard from "pages/Analytics/OrganizationsDashboard"
import CategoryDashboard from "pages/Analytics/CategoryDashboard"
import QuestionDashboard from "pages/Analytics/QuestionDashboard"
import { getAuthenticatedUser } from "api/user"
import SectorList from "pages/Configurations/SectorList"
import BulkSms from "pages/BulkSms"

const getAuthenticatedRoutes = () => {
  const user = getAuthenticatedUser()
  const authProtectedRoutes = [
    {
      path: "/dashboard",
      component: user?.isDefaultPassword ? <UserProfile /> : <Dashboard />,
    },

    // //profile
    { path: "/profile", component: <UserProfile /> },
    { path: "/incoming-reports", component: <ReportList /> },

    { path: "/new-reports", component: <NewReport /> },
    { path: "/referred-reports", component: <ReferredReport /> },
    { path: "/solved-reports", component: <SolvedReport /> },
    { path: "/dismissed-reports", component: <DismissedReport /> },
    { path: "/banned-reports", component: <BannedReport /> },
    { path: "/archived-reports", component: <ArchivedReport /> },
    { path: "/search-report", component: <SearchReport /> },
    { path: "/add-report", component: <ManualReport /> },

    { path: "/report/:id", component: <ReportDetail /> },

    { path: "/regions", component: <RegionList /> },
    { path: "/sectors", component: <SectorList /> },
    { path: "/languages", component: <LanguageList /> },
    { path: "/bulk-sms", component: <BulkSms /> },
    { path: "/report-types", component: <ReportTypeList /> },
    { path: "/black-listed-words", component: <BlackListedWords /> },
    {
      path: "/organizations",
      component: checkPermissions(["can_view_organizations"]) ? (
        <OrganizationList />
      ) : (
        <Dashboard />
      ),
    },
    {
      path: "/users",
      component: checkPermissions(["can_view_users"]) ? (
        <UserList />
      ) : (
        <Dashboard />
      ),
    },
    {
      path: "/developers",
      component: checkPermissions(["can_view_users"]) ? (
        <DeveloperUserList />
      ) : (
        <Dashboard />
      ),
    },

    { path: "/subscribers", component: <SubscriberList /> },
    { path: "/questions", component: <QuestionList /> },

    { path: "/status-analytics", component: <StatusDashboard /> },
    { path: "/region-analytics", component: <RegionsDashboard /> },
    { path: "/category-analytics", component: <CategoryDashboard /> },
    { path: "/organization-analytics", component: <OrganizationsDashboard /> },
    { path: "/question-analytics", component: <QuestionDashboard /> },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    {
      path: "/",
      exact: true,
      component: <Navigate to="/dashboard" />,
    },
  ]

  return authProtectedRoutes
}

const publicRoutes = [
  // { path: "", component: <ConferenceLanding /> },
  { path: "", component: <Logout /> },
  { path: "/logout", component: <Login /> },

  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/reset-password/:id", component: <ResetPassword /> },
]

export { getAuthenticatedRoutes, publicRoutes }
