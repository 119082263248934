import React, { useEffect, useState, useMemo } from "react"
import withRouter from "components/Common/withRouter"
import { map } from "lodash"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toastr from "toastr";
import { Link } from "react-router-dom"
import { initiateSwalPrompt, invokeApiCall, invokeUpdateApiCall } from "../../api"
import {
  Container,
  Card,
  CardBody,
  Table,
  Input,
  UncontrolledTooltip,
  CardTitle,
  Button,
} from "reactstrap"

import {
  OrderId,
  DateFormatter,
  SubscriberStatus
} from "../../templates/tableCol"
import TableContainer from "../../components/Common/TableContainer"
import CustomModal from "../../components/Common/CustomModal"
import { Can } from "permissions/Can";
import Pagination from "components/Common/Pagination";
import SubscriberFilter from "./SubscriberFilter";

const SubscriberList = () => {
  //meta title
  document.title = "Subscribers"
  const [record, setRecord] = useState()
  const [subscriberDetail, setsubscriberDetail] = useState()
  const [records, setRecords] = useState([])
  const [showModal, setshowModal] = useState(false)

  const [answers, setAnswers] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [openReportModal, setOpenReportModal] = useState(false)
  const [subscriberReports, setSubscriberReports] = useState(null)

  const [paginationParams, setpaginationParams] = useState({
    totalRecords: 0,
    currentPage: 1,
  })

  const onEditAnswers = e => {
    setAnswers({
      ...answers,
      [e.target.name]: e.target.value,
    })
  }

  const updateRecord = (id, payload) => {
    initiateSwalPrompt().then(value => {
      if (value) {
        invokeUpdateApiCall("UPDATE_SUBSCRIBER", payload, id).then((res) => {
          if (res) {
            toastr.success("Success")
            fetchRecords();
            setshowModal(false)
          }
        });
      }
    })
  }

  const handleDeleteEvent = () => {
    toggle()
  }
  const fetchRecords = params => {
    invokeApiCall("FETCH_SUBSCRIBERS", params).then(res => {
      const results = res.data
      setpaginationParams({
        totalRecords: results?.count,
        filterParams: params,
      })
      setCurrentPage(params?.page || currentPage)
      setRecords(results.results)
    })
  }

  const getRecord = id => {
    const params = id
    // fetch details & popup
    invokeApiCall("FETCH_SUBSCRIBER_DETAIL", params).then(res => {
      const results = res.data
      setsubscriberDetail(results)
      setshowModal(true)
    })
  }

  const handleUserClick = arg => {
    const details = arg

    setRecord({
      id: details.id,
      name: details.name,
      code: details.code,
    })
    setshowModal(true)
  }

  const fetchPaginated = page => {
    const paginationParams = {
      page: page
    }
    fetchRecords(paginationParams)
  }

  useEffect(() => {
    fetchRecords()
  }, [])

  const getSubsciberRecord = params => {
    invokeApiCall("FETCH_SUBSCRIBER_REPORTS", params)
      .then(res => {
        const results = res.data
        setSubscriberReports({ subscriber: params.subscriber, ...results });
        setOpenReportModal(true)
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Phone number",
        accessor: "phone_number",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "is_black_listed",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <SubscriberStatus {...cellProps} />
        },
      },
      {
        Header: "Date Added",
        accessor: "created_at",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DateFormatter {...cellProps} />
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div className="d-flex gap-3">
              <Button
                outline
                type="button"
                color="info"
                className="btn-sm btn-rounded"
                onClick={e => {
                  e.preventDefault()
                  getRecord(row.original.id)
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Button>
              <Can I='user' a='can_update_subscriber'>
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded"
                  onClick={e => {
                    e.preventDefault()
                    const payload = {
                      "clearbio": row.original.id
                    }
                    updateRecord(row.original.id, payload)
                  }}
                >
                  <i className={`bx bx-user-minus font-size-18`} id="cleartooltip" />
                  <UncontrolledTooltip placement="top" target="cleartooltip">
                    Reset
                  </UncontrolledTooltip>
                </Button>
              </Can>
              <Can I='user' a='can_update_subscriber'>
                <Button
                  type="button"
                  color={row.original.is_black_listed ? "warning" : "danger"}
                  className="btn-sm btn-rounded"
                  onClick={e => {
                    e.preventDefault()
                    const payload = {
                      "id": row.original.id,
                      "is_black_listed": !row.original.is_black_listed
                    }
                    updateRecord(row.original.id, payload)
                  }}
                >
                  <i className={`mdi mdi-${row.original.is_black_listed ? "update" : "block-helper"} font-size-18`} id="blocktooltip" />
                  <UncontrolledTooltip placement="top" target="blocktooltip">
                    Block / UnBlock
                  </UncontrolledTooltip>
                </Button>
              </Can>
              <Button
                outline
                type="button"
                color="secondary"
                className="btn-sm btn-rounded"
                onClick={e => {
                  e.preventDefault()
                  getSubsciberRecord({ subscriber: row.original.id })
                }}
              >
                <i className="mdi mdi-file-document font-size-18" id="reporttooltip" />
                <UncontrolledTooltip placement="top" target="reporttooltip">
                  Reports
                </UncontrolledTooltip>
              </Button>

            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <CustomModal
        show={showModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setshowModal(false)}
        addSubmitButton
        onActionSubmit={() => {
          updateRecord(subscriberDetail?.id, answers)
        }}
        size="xl"
        title="Subscriber details"
      >
        <Card>
          <CardBody>
            <CardTitle className="mb-4 text-start">Answers</CardTitle>
            <div className="table-responsive">
              <Table className="table-wrap align-middle table-hover mb-0 text-start">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "100px" }}>
                      #
                    </th>
                    <th scope="col">Question</th>
                    <th scope="col">Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {map(subscriberDetail?.answers, (record, index) => (
                    <tr key={index}>
                      <td> {index + 1}</td>
                      <td> {record.subscriber_question?.question}</td>
                      <td>
                        {record.subscriber_question?.question_option
                          ?.length < 1 ? (
                          <Input
                            name={record.id}
                            onChange={onEditAnswers}
                            type="text"
                            value={
                              answers[record.id] ??
                              record.answer
                            }
                          />
                        ) : (
                          <Input
                            name={record.id}
                            type="select"
                            onChange={onEditAnswers}
                            value={
                              answers[record.id] ??
                              record.answer
                            }
                          >
                            {record.subscriber_question?.question_option?.map(
                              (item, index) => (
                                <option key={index} value={item.option}>
                                  {item.option}
                                </option>
                              )
                            )}
                          </Input>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

      </CustomModal>

      <ReportModal
        openReportModal={openReportModal}
        setOpenReportModal={setOpenReportModal}
        subscriberReports={subscriberReports}
        getSubsciberRecord={getSubsciberRecord}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="subscribers" />

          <Card>
            <CardBody>
              <SubscriberFilter
                fetchRecords={fetchRecords}
              />
              <TableContainer
                columns={columns}
                data={records}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={10}
              />
              <Pagination
                totalRows={paginationParams.totalRecords}
                pageChangeHandler={fetchPaginated}
                rowsPerPage={10}
                currentPage={currentPage}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SubscriberList)


const ReportModal = ({
  openReportModal,
  setOpenReportModal,
  subscriberReports,
  getSubsciberRecord
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const fetchPaginatedReports = page => {
    setCurrentPage(page || 1)
    let params = {
      page: page,
      subscriber: subscriberReports.subscriber
    }
    getSubsciberRecord(params)
  }

  return (
    <CustomModal
      show={openReportModal}
      onCloseClick={() => setOpenReportModal(false)}
      size="xl"
      title="Subscriber Reports"
    >
      <Card>
        <CardBody>
          <div className="table-responsive">
            <Table className="table-wrap align-middle table-hover mb-0 text-start">
              <thead>
                <tr>
                  <th scope="col">Reference Number</th>
                  <th scope="col">Report</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {map(subscriberReports?.results, (record, index) => (
                  <tr key={index}>
                    <td> {record.reference_number}</td>
                    <td> {record.input_text}</td>
                    <td>
                      {record.status}
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: `/report/${record.id}`,
                        }}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <Pagination
        totalRows={subscriberReports?.count}
        pageChangeHandler={fetchPaginatedReports}
        rowsPerPage={10}
        currentPage={currentPage}
      />
    </CustomModal>
  )
}