import React from "react";
import withRouter from "components/Common/withRouter";

import {
  OrderId,
} from "../../templates/tableCol";
import TableContainer from "../../components/Common/TableContainer";

const AnalyticsList = props => {

  const columns = [

    {
      Header: "Name",
      accessor: "name",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        const value = {...cellProps, value: cellProps.row.original.name || "Unknown"}
        return <OrderId {...value} />;
      },
    },
    {
      Header: "Total",
      accessor: "total",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <OrderId {...cellProps} />;
      },
    }
  ]

  return (
    <React.Fragment>
      <TableContainer
        columns={columns}
        data={props.records}
        isGlobalFilter={false}
        isAddOptions={false}
        customPageSize={50}
      />
    </React.Fragment>
  );
};

export default withRouter(AnalyticsList);
