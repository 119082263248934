import React, { useEffect, useState, useMemo } from "react";
// import { Link, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { isEmpty, map } from "lodash";
import * as moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { initiateSwalPrompt, invokeApiCall, invokeUpdateApiCall } from '../../api';
import {
  Badge,
  Col,
  Container,
  Card, CardBody,
  Row,
  Table,
  Label,
  Form,
  FormFeedback,
  Input,
  UncontrolledTooltip,
  Button
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

import {
  OrderId,
  DateFormatter,
  Total,
  InvoiceStatus,
  PaymentStatus
} from "../../templates/tableCol";
import TableContainer from "../../components/Common/TableContainer";
import CustomModal from "../../components/Common/CustomModal";


const LanguageList = () => {

  //meta title
  document.title = "Languages | Languages";
  const dispatch = useDispatch();
  const [record, setRecord] = useState();
  const [records, setRecords] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (record && record.name) || "",
      code: (record && record.code) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      code: Yup.string().required("Please Enter Code"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateRecord = {
          id: record.id,
          name: values.name,
          code: values.code,
        };
        updateEdittedRecord(updateRecord);


      } else {
        const newRecord = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          code: values["code"],
        };
        insertRecord(newRecord);
      }
    },
  });

  const handleDeleteEvent = () => {
    toggle();
  };
  const fetchRecords = (params) => {
    invokeApiCall("FETCH_LANGUAGES", params).then((res) => {
      const results = res.data;
      setRecords(results.results)

    });
  }


  const createRecord = () => {

    setIsEdit(false);
    setshowModal(true)
  };

  const editRecord = (id) => {
    const params = id;
    // fetch details & popup
    invokeApiCall("FETCH_LANGUAGE_DETAIL", params).then((res) => {
      const results = res.data;
      handleUserClick(results);
    });
  }
  const updateEdittedRecord = (payload) => {
    invokeApiCall("UPDATE_LANGUAGE", payload).then((res) => {
      const results = res.data;
      setshowModal(false)
      fetchRecords();

    });

  }

  const insertRecord = (payload) => {
    invokeApiCall("CREATE_LANGUAGE", payload).then((res) => {
      const results = res.data;
      setshowModal(false)
      fetchRecords();

    });

  }



  const removeRecord = (payload) => {
    initiateSwalPrompt().then(value => {
      if (value) {
        invokeUpdateApiCall("DELETE_LANGUAGE", {}, payload).then((res) => {
          fetchRecords();
        });
      }
    })
  }


  const handleUserClick = arg => {
    const details = arg;

    setRecord({
      id: details.id,
      name: details.name,
      code: details.code
    });
    setIsEdit(true);
    setshowModal(true)

  };
  useEffect(() => {
    fetchRecords()
  }, [])
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y HH:MM:SS");
    return date1;
  };
  const columns = useMemo(
    () => [

      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />;
        },
      },
      {
        Header: "Code",
        accessor: "code",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />;
        },
      },


      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: ({ row }) => {
          return (


            <div className="d-flex gap-3">
              <Button
                type="button"
                color="success"
                className="btn-sm btn-rounded"
                onClick={(e) => {
                  e.preventDefault();
                  editRecord(row.original.id)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>


              </Button>
              <Button
                type="button"
                color="danger"
                className="btn-sm btn-rounded"
                onClick={(e) => {
                  e.preventDefault();
                  removeRecord(row.original.id)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Remove
                </UncontrolledTooltip>


              </Button>
            </div>
          );
        },

      },
    ], [])

  return (
    <React.Fragment>
      <CustomModal
        show={showModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setshowModal(false)}
        size="md"
        addSubmitButton
        onActionSubmit={() => {
          validation.handleSubmit()
        }}
        title="Language details">
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">Name</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder="Insert Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name &&
                      validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name &&
                  validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Code</Label>
                <Input
                  name="code"
                  type="text"
                  placeholder="Insert code"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.code || ""}
                  invalid={
                    validation.touched.code &&
                      validation.errors.code
                      ? true
                      : false
                  }
                />
                {validation.touched.code &&
                  validation.errors.code ? (
                  <FormFeedback type="invalid">
                    {validation.errors.code}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </Form>

      </CustomModal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="supported languages" />

          <Row>
            <Col lg="12">
              <Card>

                <CardBody>
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={createRecord}
                    >
                      <i className="mdi mdi-plus me-1" />
                      Add Language
                    </Button>
                  </div>
                  <div className="mb-4 h4 card-title">All records</div>
                  <TableContainer
                    columns={columns}
                    data={records}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={6}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(LanguageList);
