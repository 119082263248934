import jwt_decode from "jwt-decode";

export const getAuthenticatedUser = () => {
    const authenticatedUserToken = localStorage.getItem("authUser");
    if (authenticatedUserToken) {
        const decodeData = jwt_decode(authenticatedUserToken);
        const userDetails = {
            "id": decodeData?.id,
            "firstName": decodeData?.first_name,
            "lastName": decodeData?.surname,
            "fullName": decodeData?.full_name,
            "otherNames": decodeData?.other_names,
            "email": decodeData?.email,
            "phoneNumber": decodeData?.phone_number,
            "userPermissions": decodeData?.permissions,
            "userRoles": decodeData?.roles,
            "isDefaultPassword": decodeData?.is_defaultpassword,
            "organization": decodeData?.organization,
            "logo": decodeData?.logo,
            "theme": decodeData?.theme,
            "regions": decodeData?.region_name,
        }
        return userDetails
    }
}