import React, { useEffect, useState } from "react"
import withRouter from "components/Common/withRouter"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { invokeApiCall } from "../../api"
import { Col, Container, Card, CardBody, Row } from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch } from "react-redux"
import ReportFilter from "./ReportFilter"
import ReportList from "./ReportList"
import Pagination from "../../components/Common/Pagination"

const ReferredReport = () => {
  const pageName = "Referred Reports"
  const reportType = "reffered"

  document.title = pageName
  const [records, setRecords] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [paginationParams, setpaginationParams] = useState({
    totalRecords: 0,
    currentPage: 1,
  })
  const filterParams = {
    report_type: reportType,
    // report_date: new Date(),
  }
  const fetchPaginated = page => {
    const paginationParams = {
      page: page,
      ...filterParams,
    }
    fetchRecords(paginationParams)
  }

  const fetchRecords = params => {
    invokeApiCall("FETCH_INCOMING_REPORTS", params).then(res => {
      const results = res.data
      setpaginationParams({
        totalRecords: results?.count,
        filterParams: params,
      })
      setRecords(results.results)
      setCurrentPage(params?.page || currentPage)
    })
  }
  useEffect(() => {
    fetchRecords(filterParams)
  }, [])
  const NavigateToPage = responseData => {
    fetchRecords(responseData)
  }

  const fetchFilteredRecords = responseData => {
    setpaginationParams({
      totalRecords: responseData?.response_data?.count,
      previous: responseData?.previous,
    })
    setCurrentPage(responseData?.page || currentPage)
    setRecords(responseData?.response_data?.results)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Reports" breadcrumbItem={pageName} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ReportFilter
                    fetchFilteredRecords={fetchFilteredRecords}
                    reportType={reportType}
                    records={records}
                  />
                  <ReportList records={records} />
                  <Pagination
                    totalRows={paginationParams.totalRecords}
                    pageChangeHandler={fetchPaginated}
                    rowsPerPage={10}
                    currentPage={currentPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ReferredReport)
