import React, { useEffect, useState, useMemo } from "react"
import withRouter from "components/Common/withRouter"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  initiateSwalPrompt,
  invokeApiCall,
  invokeUpdateApiCall,
} from "../../api"
import {
  Col,
  Container,
  Card,
  CardBody,
  Row,
  Label,
  Form,
  FormFeedback,
  Input,
  UncontrolledTooltip,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { OrderId, DateFormatter } from "../../templates/tableCol"
import TableContainer from "../../components/Common/TableContainer"
import CustomModal from "../../components/Common/CustomModal"
import { Can } from "permissions/Can"
import Pagination from "components/Common/Pagination"

import { checkPermissions } from "permissions/ability"
import { getAuthenticatedUser } from "api/user"
import toastr from "toastr"
import { CopyToClipboard } from "react-copy-to-clipboard"
const active_status = "ACTIVE"
const revoked_status = "REVOKED"

const DeveloperUserList = () => {
  //meta title
  document.title = "Api users"
  const [record, setRecord] = useState(null)
  const [records, setRecords] = useState([])
  const [Apikey, setApikey] = useState("")
  const [isNewApiKey, setisNewApiKey] = useState(false)
  const [isKeyCopied, setisKeyCopied] = useState(false)
  const [showModal, setshowModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [regions, setRegions] = useState([])
  const [organizations, setOrganizations] = useState([])
  const canAddOrganization = checkPermissions(["can_create_organization"])
  const user = getAuthenticatedUser()
  const [paginationParams, setpaginationParams] = useState({
    totalRecords: 0,
    currentPage: 1,
  })
  const [currentPage, setCurrentPage] = useState(1)

  const formValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      app_name: "",
      domain: "",
      status: "",
      date_generated: "",
      expiry_date: "",
    },
    validationSchema: Yup.object({
      app_name: Yup.string().required("Please enter app name"),
      domain: Yup.string().required("Please enter the domain"),
      status: Yup.string().required("Please select status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        var updateRecord = {
          id: record.id,
          app_name: values.app_name,
          domain: values.domain,
          status: values.status,
        }
        updateEdittedRecord(updateRecord, record?.id)
      } else {
        const newRecord = {
          app_name: values.app_name,
          domain: values.domain,
          status: values.status,
        }
        createApiRecord(newRecord)
      }
    },
  })

  const handleDeleteEvent = () => {
    toggle()
  }

  const fetchRecords = params => {
    invokeApiCall("FETCH_API_DEVELOPERS", params).then(res => {
      const results = res.data
      setpaginationParams({
        totalRecords: results?.count,
        filterParams: params,
      })
      setCurrentPage(params?.page || currentPage)
      setRecords(results.results)
    })
  }

  const createRecord = () => {
    setApikey("")
    setisKeyCopied(false)
    setIsEdit(false)
    setshowModal(true)
  }

  const editRecord = id => {
    const params = id
    // fetch details & popup
    invokeApiCall("FETCH_API_DEVELOPER_DETAIL", params).then(res => {
      const results = res.data
      handleUserClick(results)
    })
  }

  const updateEdittedRecord = (payload, pk) => {
    invokeUpdateApiCall("UPDATE_API_KEY", payload, pk).then(res => {
      const results = res.data
      setshowModal(false)
      fetchRecords()
    })
  }

  const handleUserClick = arg => {
    const details = arg

    formValidation.setValues({
      app_name: details?.app_name,
      domain: details?.domain,
      status: details?.status,
    })

    setApikey("")
    setisKeyCopied(false)

    setRecord(details)
    setIsEdit(true)
    setshowModal(true)
  }

  useEffect(() => {
    fetchRecords()
  }, [])

  const createApiRecord = payload => {
    invokeApiCall("CREATE_API_KEY", payload).then(res => {
      if (res) {
        const newKey = res?.api_key
        setApikey(newKey)
        setisNewApiKey(true)
      }
    })
  }
  const fetchPaginated = page => {
    const paginationParams = {
      page: page,
    }
    fetchRecords(paginationParams)
  }

  const columns = useMemo(
    () => [
      {
        Header: "App Name",
        accessor: "app_name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Domain",
        accessor: "domain",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Date Generated",
        accessor: "date_generated",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DateFormatter {...cellProps} />
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DateFormatter {...cellProps} />
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <div className="d-flex gap-3">
              <Button
                type="button"
                color="success"
                className="btn-sm btn-rounded"
                onClick={e => {
                  e.preventDefault()
                  editRecord(row.original.id)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Button>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <CustomModal
        show={showModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => {
          formValidation.handleReset()
          setshowModal(false)
        }}
        addSubmitButton
        onActionSubmit={() => {
          formValidation.handleSubmit()
        }}
        size="xl"
        title="Developer details"
      >
        <Form
          className="text-start"
          onSubmit={e => {
            // e.preventDefault();
            formValidation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col lg="4">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">App Name</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-firstname-input1"
                  name="app_name"
                  placeholder="Enter the app name"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.app_name || ""}
                  invalid={
                    formValidation.touched.app_name &&
                    formValidation.errors.app_name
                      ? true
                      : false
                  }
                />
                {formValidation.touched.app_name &&
                formValidation.errors.app_name ? (
                  <FormFeedback type="invalid">
                    {formValidation.errors.app_name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Domain</Label>
                <Input
                  type="text"
                  className="form-control"
                  id="basicpill-lastname-input2"
                  placeholder="Enter the Domain"
                  name="domain"
                  onChange={formValidation.handleChange}
                  onBlur={formValidation.handleBlur}
                  value={formValidation.values.domain || ""}
                  invalid={
                    formValidation.touched.domain &&
                    formValidation.errors.domain
                      ? true
                      : false
                  }
                />
                {formValidation.touched.domain &&
                formValidation.errors.domain ? (
                  <FormFeedback type="invalid">
                    {formValidation.errors.domain}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4">
              <div className="mb-3">
                <Label for="basicpill-email-input4">Status</Label>
                <Input
                  type={"select"}
                  className="form-control"
                  id="basicpill-email-input4"
                  placeholder="Select status"
                  name="status"
                  onBlur={formValidation.handleBlur}
                  onChange={e => {
                    formValidation.handleChange(e)
                  }}
                  value={formValidation.values.status || ""}
                  invalid={
                    formValidation.touched.status &&
                    formValidation.errors.status
                      ? true
                      : false
                  }
                >
                  <option value={""} disabled hidden>
                    -- --
                  </option>
                  <option value={active_status}>{active_status}</option>
                  <option value={revoked_status}>{revoked_status}</option>
                </Input>
                {formValidation.touched.status &&
                formValidation.errors.status ? (
                  <FormFeedback type="invalid">
                    {formValidation.errors.status}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </Form>

        {isNewApiKey && (
          <div>
            <br></br>
            <br></br>
            <span style={{ color: "red" }}>
              <p>Kindly copy this key!You can only view it once!!!</p>
            </span>

            <b>{Apikey}</b>

            <CopyToClipboard
              text={Apikey}
              onCopy={() => {
                setisKeyCopied(true)
              }}
            >
              <Button type="submit" color="primary">
                Copy key
              </Button>
            </CopyToClipboard>

            {isKeyCopied ? <span style={{ color: "red" }}>Copied.</span> : null}
          </div>
        )}
      </CustomModal>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="developer users" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Can I="user" a="can_create_user">
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                        onClick={createRecord}
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create new key
                      </Button>
                    </div>
                  </Can>
                  <div className="mb-4 h4 card-title">All records</div>
                  <TableContainer
                    columns={columns}
                    data={records}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={10}
                  />
                  <Pagination
                    totalRows={paginationParams.totalRecords}
                    pageChangeHandler={fetchPaginated}
                    rowsPerPage={10}
                    currentPage={currentPage}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DeveloperUserList)
