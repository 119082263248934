import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, SET_NOTIFICATIONS_COUNT, SET_NOTIFICATIONS } from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const setNotificationCount = count => {
  return {
    type: SET_NOTIFICATIONS_COUNT,
    payload: count
  }
}

export const setNotifications = payload => {
  return {
    type: SET_NOTIFICATIONS,
    payload: payload
  }
}