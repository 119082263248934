import React, { useEffect, useState } from "react";
import withRouter from "components/Common/withRouter";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { invokeApiCall } from '../../api';
import {
  Col,
  Container,
  Card, CardBody,
  Row,
  Label,
  Form,
  FormFeedback,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr"


const Index = () => {

  //meta title
  document.title = "Admin | Bulk Sms";
  const [records, setRecords] = useState([]);
  const [organizations, setOrganizations] = useState([])
  const [questions, setQuestions] = useState([])
  const [questionOptions, setQuestionOptions] = useState([])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,

    initialValues: {
      recipient: "",
      region_ids: [],
      organization_ids: [],
      message: "",
      phone_number: "",
      phone_numbers: [],
      question_options: [],
      question: "",
      question_option: "",
    },
    validationSchema: Yup.object({
      recipient: Yup.string().required("Please add the recipient"),
      phone_number: Yup.string().min(10).max(10).when(['phone_numbers', 'recipient'], {
        is: ({ phone_numbers, recipient }) => phone_numbers?.length < 1 && recipient === 'custom',
        then: Yup.string().min(10).max(10).required("Please add a phone number"),
      }),
      organization_ids: Yup.array().of(Yup.string()).when('recipient', {
        is: (recipient) => recipient === 'organization',
        then: Yup.array().min(1, "Please add an organization"),
      }),
      region_ids: Yup.array().of(Yup.string()).when('recipient', {
        is: (recipient) => recipient === 'region',
        then: Yup.array().min(1, "Please add a region"),
      }),
      phone_numbers: Yup.array().of(Yup.string()).when('recipient', {
        is: (recipient) => recipient === 'custom',
        then: Yup.array().min(1, "Please add a phone number"),
      }),
      question_options: Yup.array().of(Yup.mixed()).when('recipient', {
        is: (recipient) => recipient === 'questions',
        then: Yup.array().min(1, "Please add a question options"),
      }),
      question: Yup.string().when(['question_options', 'recipient'], {
        is: ({ question_options, recipient }) => question_options?.length < 1 && recipient === 'questions',
        then: Yup.string().required("Please add a question"),
      }),
      question_option: Yup.mixed().when(['question_options', 'recipient'], {
        is: ({ question_options, recipient }) => question_options?.length < 1 && recipient === 'questions',
        then: Yup.mixed().required("Please add a question option"),
      }),
      message: Yup.string().required("Please add the message")
    }),
    onSubmit: values => {
      delete values.phone_number;
      delete values.question;
      delete values.question_option;

      sendSms(values)
    },
  });

  const fetchRecords = () => {
    const params = {
      page_size: 100,
    }
    invokeApiCall("FETCH_REGIONS", params).then((res) => {
      const results = res.data;
      setRecords(results.results)

    });
  }
  const fetchOrganizations = () => {
    const params = {
      page_size: 100,
    }
    invokeApiCall("FETCH_ORGANIZATIONS", params).then((res) => {
      const results = res.data;
      setOrganizations(results.results)
    });
  }


  const sendSms = (payload) => {
    invokeApiCall("SEND_BULK_SMS", payload).then((res) => {
      if (res) {
        toastr.success(res.message)
        validation.resetForm()
      }
    });

  }

  useEffect(() => {
    fetchRecords()
    fetchOrganizations()
    fetchQuestions()
  }, [])

  const removePhoneFromList = (phone_number) => {
    validation.setFieldValue('phone_numbers', validation.values.phone_numbers?.filter(item => item != phone_number))
  }

  const fetchQuestions = () => {
    const params = {
      page_size: 100,
    }
    invokeApiCall("FETCH_QUESTIONS", params).then((res) => {
      const results = res.data;
      setQuestions(results.results?.filter(item => item.is_bio_question))
    });
  }


  const getQuestionOptions = (e) => {
    const question = e.target.value
    const selectedQuestion = questions?.filter(item => item.question === question)[0]
    setQuestionOptions(selectedQuestion?.question_options)
  }

  const removeQuestionOptionFromList = (option_id) => {
    validation.setFieldValue('question_options', validation.values.question_options?.filter(item => item.option_id != option_id))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Send Bulk SMS" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col className="col-xl-7">
                        <div className="mb-3">
                          <Label for="basicpill-email-input4">Recipient</Label>
                          <Input
                            type={"select"}
                            name="recipient"
                            onChange={(e) => {
                              validation.resetForm()
                              validation.handleChange(e)
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.recipient || ""}
                            invalid={
                              validation.touched.recipient &&
                                validation.errors.recipient
                                ? true
                                : false
                            }
                          >
                            <option value={""} disabled >-----</option>
                            {Recipients?.map((item, i) => (
                              <option key={i} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Input>

                          {(validation.touched.recipient && validation.errors.recipient) && (
                            <FormFeedback type="invalid">
                              {validation.errors.recipient}
                            </FormFeedback>
                          )
                          }
                        </div>

                        {validation.values.recipient === 'organization' &&

                          <div className="mb-3">
                            <Label for="basicpill-email-input4">Organization</Label>
                            <Input
                              multiple
                              type={"select"}
                              name="organization_ids"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.organization_ids || []}
                              invalid={
                                validation.touched.organization_ids && validation.errors.organization_ids ? true : false
                              }
                            >
                              {organizations?.map((item, i) => (
                                <option key={i} value={`${item.id}`}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>

                            {(validation.touched.organization_ids && validation.errors.organization_ids) && (
                              <FormFeedback type="invalid">
                                {validation.errors.organization_ids}
                              </FormFeedback>
                            )
                            }
                          </div>
                        }
                        {validation.values.recipient === 'region' &&

                          <div className="mb-3">
                            <Label for="basicpill-email-input4">Region</Label>
                            <Input
                              type={"select"}
                              name="region_ids"
                              multiple
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.region_ids || ""}
                              invalid={
                                validation.touched.region_ids &&
                                  validation.errors.region_ids
                                  ? true
                                  : false
                              }
                            >
                              {records?.length &&
                                records?.map((item, i) => (
                                  <option key={i} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </Input>

                            {(validation.touched.region_ids && validation.errors.region_ids) && (
                              <FormFeedback type="invalid">
                                {validation.errors.region_ids}
                              </FormFeedback>
                            )
                            }
                          </div>
                        }
                        {
                          validation.values.recipient === 'questions' && (
                            <>
                              <Row className="d-flex align-items-sm-center">
                                <Col xs={5}>
                                  <div className="mb-3">
                                    <Label for="basicpill-email-input4">Question</Label>
                                    <Input
                                      type={"select"}
                                      name="question"
                                      onChange={(e) => {
                                        validation.handleChange(e)
                                        getQuestionOptions(e)
                                      }}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.question || ""}
                                      invalid={
                                        validation.touched.question &&
                                          validation.errors.question
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value={""} disabled >-----</option>
                                      {questions?.map((item, i) => (
                                        <option key={i} value={item.question}>
                                          {item.question}
                                        </option>
                                      ))}
                                    </Input>

                                    {(validation.touched.question && validation.errors.question) && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.question}
                                      </FormFeedback>
                                    )
                                    }
                                  </div>
                                </Col>
                                <Col xs={5}>
                                  <div className="mb-3">
                                    <Label for="basicpill-email-input4">Question Options</Label>
                                    <Input
                                      disabled={!validation.values.question}
                                      type={"select"}
                                      name="question_option"
                                      onChange={(e) => {
                                        validation.setFieldValue('question_option', JSON.parse(e.target.value))
                                      }}
                                      onBlur={validation.handleBlur}
                                      // value={validation.values.question_option || ""}
                                      invalid={
                                        validation.touched.question_option &&
                                          validation.errors.question_option
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value={""} >-----</option>
                                      {questionOptions?.map((item, i) => (
                                        <option key={i} value={
                                          JSON.stringify({
                                            "question": validation.values.question,
                                            "option_id": item.id,
                                            "option": item.option,
                                          })}>
                                          {item.option}
                                        </option>
                                      ))}
                                    </Input>

                                    {(validation.touched.question_option && validation.errors.question_option) && (
                                      <FormFeedback type="invalid">
                                        {validation.errors.question_option}
                                      </FormFeedback>
                                    )
                                    }
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div>
                                    <button
                                      disabled={validation.isSubmitting}
                                      type="button"
                                      className="btn btn-primary px-3 w-100"
                                      onClick={() => {
                                        validation.validateField('question_option').then(() => {
                                          if (!validation.errors.question_option) {
                                            if (!(validation.values.question_options.some(e => e.option_id === validation.values.question_option?.option_id))) {
                                              validation.setFieldValue('question_options', [validation.values.question_option, ...validation.values.question_options])
                                            } else {
                                              validation.setFieldError('question_option', 'Option already exists!')
                                            }
                                          } else {
                                            validation.setFieldError('question_option', 'Please add valid question option')
                                          }
                                        })
                                      }}
                                    >
                                      ADD
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="d-flex gap-2 align-items-sm-center m-1">
                                {
                                  validation.values.question_options?.map((item, i) => (
                                    <Col xs='6' key={i} className="p-1 text-center" style={{
                                      background: '#E5EBF3',
                                      borderRadius: '3px',
                                      cursor: 'pointer'
                                    }}
                                    >
                                      <div className="d-flex justify-content-between px-3">
                                        <small style={{
                                          fontSize: '11px',
                                          display: 'flex',
                                          gap: "10px"
                                        }}> <span>{item.question} :</span><span>{item.option}</span></small>
                                        <i
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            removeQuestionOptionFromList(item.option_id)
                                          }}
                                          className="mdi mdi-trash-can-outline" style={{
                                            cursor: 'pointer'
                                          }}></i>
                                      </div>
                                    </Col>
                                  ))
                                }
                              </Row>
                            </>
                          )
                        }
                        {validation.values.recipient === 'custom' &&
                          <>
                            <Row className="d-flex align-items-sm-center">
                              <Col xs={10}>
                                <div className="mb-3">
                                  <Label className="form-label">Phone Numbers</Label>
                                  <Input
                                    name="phone_number"
                                    type="text"
                                    placeholder="Phone number"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone_number || ""}
                                    invalid={
                                      validation.touched.phone_number &&
                                        validation.errors.phone_number
                                        ? true
                                        : false
                                    }
                                  />
                                  {(validation.touched.phone_number && validation.errors.phone_number) && (
                                    <FormFeedback type="invalid">
                                      {validation.errors.phone_number}
                                    </FormFeedback>
                                  )
                                  }
                                </div>
                              </Col>
                              <Col xs={2}>
                                <div>
                                  <button
                                    disabled={validation.isSubmitting}
                                    type="button"
                                    className="btn btn-primary px-3 w-100 mt-1"
                                    onClick={() => {
                                      validation.validateField('phone_number').then(() => {
                                        if (!validation.errors.phone_number) {
                                          if (!validation.values.phone_numbers?.includes(validation.values.phone_number)) {
                                            validation.setFieldValue('phone_numbers', [validation.values.phone_number, ...validation.values.phone_numbers])
                                            validation.setFieldValue('phone_number', '')
                                          } else {
                                            validation.setFieldError('phone_number', 'Phone number already exists!')
                                          }
                                        } else {
                                          validation.setFieldError('phone_number', 'Please add valid phone number')
                                        }
                                      })
                                    }}
                                  >
                                    ADD
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row className="d-flex gap-2 align-items-sm-center m-1">
                              {
                                validation.values.phone_numbers?.map((item, i) => (
                                  <Col xs='2' key={i} className="p-1 text-center" style={{
                                    background: '#E5EBF3',
                                    borderRadius: '3px',
                                    cursor: 'pointer'
                                  }}
                                    onClick={() => validation.setFieldValue('phone_number', item)}
                                  >
                                    <div className="d-flex justify-content-center gap-3">
                                      {item}
                                      <i
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          removePhoneFromList(item)
                                        }}
                                        className="mdi mdi-trash-can-outline" style={{
                                          cursor: 'pointer'
                                        }}></i>
                                    </div>
                                  </Col>
                                ))
                              }
                            </Row>
                          </>
                        }
                        <div className="mb-3">
                          <Label className="form-label">Message</Label>
                          <Input
                            name="message"
                            type="textarea"
                            rows="4"
                            placeholder="Type in your message"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.message || ""}
                            invalid={
                              validation.touched.message &&
                                validation.errors.message
                                ? true
                                : false
                            }
                          />
                          {
                            (validation.touched.message && validation.errors.message) && (
                              <FormFeedback type="invalid">
                                {validation.errors.message}
                              </FormFeedback>
                            )
                          }
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            disabled={validation.isSubmitting}
                            type="submit"
                            className="btn btn-primary px-5"
                          // onClick={() => {
                          //   console.log(validation.errors);
                          // }}
                          >
                            SEND
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Index);


const Recipients = [
  {
    "label": "Region",
    "value": "region",
  },
  {
    "label": "Organization",
    "value": "organization",
  },
  {
    "label": "Questions",
    "value": "questions",
  },
  {
    "label": "Custom",
    "value": "custom",
  }
]